import * as geotiff from "geotiff";
import * as geokeysToProj4 from "geotiff-geokeys-to-proj4";
import proj4 from "proj4";
import {
  binaryPalette,
  ironPalette,
  rainbowPalette,
  renderPalette,
  renderRGB,
  sunlightPalette,
} from "./helpers";
import { apiUrl, appType } from "../config/paths";
import { FaCirclePlus } from "react-icons/fa6";
import {
  IoNotificationsSharp,
  IoSettings,
  IoTicketSharp,
} from "react-icons/io5";
import { BsBorder, BsCalendarEvent } from "react-icons/bs";
import { FaClipboardList, FaUserCircle } from "react-icons/fa";
import paths from "../config/paths";

export async function getDataLayerUrls(location, radius_meters, apiKey) {
  const args = {
    "location.latitude": location.latitude.toFixed(5),
    "location.longitude": location.longitude.toFixed(5),
    radius_meters: radius_meters.toString(),
  };
  const params = new URLSearchParams({ ...args, key: apiKey });
  return fetch(`https://solar.googleapis.com/v1/dataLayers:get?${params}`).then(
    async (response) => {
      const content = await response.json();
      if (response.status !== 200) {
        console.error("getDataLayerUrls\n", content);
        throw content;
      }
      console.log("dataLayersResponse", content);
      return content;
    }
  );
}

export async function downloadGeoTIFF(url, apiKey) {
  console.log(`Downloading data layer: ${url}`);
  const solarUrl = url.includes("solar.googleapis.com")
    ? url + `&key=${apiKey}`
    : url;
  const response = await fetch(solarUrl);
  if (response.status !== 200) {
    const error = await response.json();
    console.error(`downloadGeoTIFF failed: ${url}\n`, error);
    throw error;
  }
  const arrayBuffer = await response.arrayBuffer();
  const tiff = await geotiff.fromArrayBuffer(arrayBuffer);
  const image = await tiff.getImage();
  const rasters = await image.readRasters();

  // Reproject the bounding box into coordinates.
  const geoKeys = image.getGeoKeys();
  const projObj = geokeysToProj4.toProj4(geoKeys);
  const projection = proj4(projObj.proj4, "WGS84");
  const box = image.getBoundingBox();
  const sw = projection.forward({
    x: box[0] * projObj.coordinatesConversionParameters.x,
    y: box[1] * projObj.coordinatesConversionParameters.y,
  });
  const ne = projection.forward({
    x: box[2] * projObj.coordinatesConversionParameters.x,
    y: box[3] * projObj.coordinatesConversionParameters.y,
  });

  return {
    width: rasters.width,
    height: rasters.height,
    rasters: [...Array(rasters.length).keys()].map((i) =>
      Array.from(rasters[i])
    ),
    bounds: {
      north: ne.y,
      south: sw.y,
      east: ne.x,
      west: sw.x,
    },
  };
}

export async function getLayer(layerId, urls, googleMapsApiKey) {
  const get = {
    mask: async () => {
      const mask = await downloadGeoTIFF(urls.maskUrl, googleMapsApiKey);
      const colors = binaryPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "No roof",
          max: "Roof",
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: mask,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
          }),
        ],
      };
    },
    dsm: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.dsmUrl, googleMapsApiKey),
      ]);
      const sortedValues = Array.from(data.rasters[0]).sort((x, y) => x - y);
      const minValue = sortedValues[0];
      const maxValue = sortedValues.slice(-1)[0];
      const colors = rainbowPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: `${minValue.toFixed(1)} m`,
          max: `${maxValue.toFixed(1)} m`,
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: data,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
            min: sortedValues[0],
            max: sortedValues.slice(-1)[0],
          }),
        ],
      };
    },
    rgb: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.rgbUrl, googleMapsApiKey),
      ]);
      return {
        id: layerId,
        bounds: mask.bounds,
        render: (showRoofOnly) => [
          renderRGB(data, showRoofOnly ? mask : undefined),
        ],
      };
    },
    annualFlux: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.annualFluxUrl, googleMapsApiKey),
      ]);
      const colors = ironPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shady",
          max: "Sunny",
        },
        render: (showRoofOnly) => [
          renderPalette({
            data: data,
            mask: showRoofOnly ? mask : undefined,
            colors: colors,
            min: 0,
            max: 1800,
          }),
        ],
      };
    },
    monthlyFlux: async () => {
      const [mask, data] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        downloadGeoTIFF(urls.monthlyFluxUrl, googleMapsApiKey),
      ]);
      const colors = ironPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shady",
          max: "Sunny",
        },
        render: (showRoofOnly) =>
          [...Array(12).keys()].map((month) =>
            renderPalette({
              data: data,
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 200,
              index: month,
            })
          ),
      };
    },
    hourlyShade: async () => {
      const [mask, ...months] = await Promise.all([
        downloadGeoTIFF(urls.maskUrl, googleMapsApiKey),
        ...urls.hourlyShadeUrls.map((url) =>
          downloadGeoTIFF(url, googleMapsApiKey)
        ),
      ]);
      const colors = sunlightPalette;
      return {
        id: layerId,
        bounds: mask.bounds,
        palette: {
          colors: colors,
          min: "Shade",
          max: "Sun",
        },
        render: (showRoofOnly, month, day) =>
          [...Array(24).keys()].map((hour) =>
            renderPalette({
              data: {
                ...months[month],
                rasters: months[month].rasters.map((values) =>
                  values.map((x) => x & (1 << (day - 1)))
                ),
              },
              mask: showRoofOnly ? mask : undefined,
              colors: colors,
              min: 0,
              max: 1,
              index: hour,
            })
          ),
      };
    },
  };
  try {
    return get[layerId]();
  } catch (e) {
    console.error(`Error getting layer: ${layerId}\n`, e);
    throw e;
  }
}

export const getFolderPath =
  appType === "local"
    ? apiUrl + "/storage/uploads/"
    : apiUrl + "/uploads/uploads/";

export const mobileMenuList = [
  {
    name: "orders",
    value: "orders",
    path: paths.myOrders,
    icon: <BsBorder />,
  },
  {
    name: "tickets",
    value: "tickets",
    path: paths.myTicketsPath,
    icon: <IoTicketSharp />,
  },
  {
    name: "profile",
    value: "profile",
    path: paths.profilePath,
    icon: <FaUserCircle />,
  },
  /*{
    name: "quotes",
    value: "quotes",
    path: paths.myQuotesPath,
    icon: <FaClipboardList />,
  },
 
  {
    name: "appointments",
    value: "appointments",
    path: paths.appointments,
    icon: <BsCalendarEvent />,
  },
  {
    name: "settings",
    value: "settings",
    path: paths.preferencesPath,
    icon: <IoSettings />,
  },*/
];

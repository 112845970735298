import React from "react";
import { apiUrl } from "../../config/paths";
import { getFolderPath } from "../../utils/api";
import { toast } from "react-toastify";
import { currencyType, getT } from "../../admin/utils";
export default function CartProduct({
  item = {},
  index = 0,
  order = {},
  onUpdateQuantity = () => {},
}) {
  const getInfoByType = (type, product) => {
    let items = [];
    if (product && product.infos && product.infos.length > 0) {
      product.infos.forEach((i) => {
        if (i.type === type) {
          items.push(i);
        }
      });
    }
    return items;
  };

  const getVariations = (id) => {
    let items = [];
    if (order?.variations && order.variations.length > 0) {
      for (let index = 0; index < order.variations.length; index++) {
        if (
          order.variations[index].option &&
          order.variations[index].option?.product_id === id
        ) {
          items.push(order.variations[index]);
        }
      }
    }
    return items;
  };

  return (
    <>
      <div className="cart-product-list-item" key={index}>
        <div className="info">
          <span>
            {index + 1 + ": "}
            {
              <img
                height={50}
                width={50}
                style={{ padding: "5px" }}
                src={
                  getInfoByType("gallery", item.product) &&
                  getInfoByType("gallery", item?.product).length > 0
                    ? getFolderPath +
                      getInfoByType("gallery", item?.product)[0]?.file?.name
                    : ""
                }
                alt=""
              />
            }
          </span>
          <span> {item?.product?.name}</span>
          <div className="quantity">
            <span>{getT("quantity")}:</span>
            <span className="q_info">
              <button
                onClick={() => {
                  onUpdateQuantity(parseInt(item.quantity) + 1, item?.product);
                }}
                className="btn btn-success"
              >
                +
              </button>
              <span>{" " + item.quantity + " "}</span>

              <button
                onClick={() => {
                  onUpdateQuantity(item.quantity - 1, item?.product);
                }}
                className="btn btn-danger"
              >
                -
              </button>
            </span>
          </div>
          <div className="price">
            {
              <span>
                {getT("price")}: {item?.product?.price + currencyType}{" "}
              </span>
            }
            <span>{item?.product?.price * item.quantity + currencyType}</span>
          </div>
          {getVariations(item.product_id) &&
            getVariations(item.product_id).length > 0 && (
              <>
                <p>
                  <b>{getT("variations")}</b>
                </p>
                <div className="variation_items">
                  {getVariations(item.product_id) &&
                    getVariations(item.product_id).map((v) => {
                      return (
                        <>
                          <div className="variation_item">
                            {v?.option?.name +
                              " - " +
                              v?.option?.value +
                              currencyType}
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrderQuery } from "../../queries/orders";
import Loading from "../../components/Loading";
import paths from "../../config/paths";
import Badge from "react-bootstrap/Badge";
import Nav from "react-bootstrap/Nav";
import AddressInfo from "./AddressInfo";
import {
  currencyType,
  getT,
  getTotalPrice,
  getVariations,
  translationKeys,
} from "../utils";

export default function InfoOrder({
  roleType = "",
  cusId = null,
  orderId = null,
}) {
  const getCustomQuery = () => {
    let item = { customerId: cusId };
    if (roleType === "customer") {
      item["type"] = roleType;
    }
    return item;
  };
  const [order, setOrder] = useState();
  const { data, isIdle, isLoading, isError, error } = useOrderQuery(
    orderId,
    orderId ? true : false,
    getCustomQuery()
  );
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const handleSelect = (eventKey) => {
    setActiveTab(parseInt(eventKey));
  };

  useEffect(() => {
    if (data && !isLoading && data.item) {
      setOrder(data?.item);
    }

    //console.log(data);
  }, [isLoading, data]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    roleType !== "customer"
      ? navigate(paths.ordersPath)
      : navigate(paths.myOrders);
  }

  return (
    <>
      <div className="p-1 orderDetail">
        <p>
          {getT(translationKeys.payment_status_label)}:{" "}
          <Badge bg="dark">{order?.payment_status}</Badge>
        </p>
        <Nav
          variant="tabs"
          className="m-2"
          activeKey={activeTab}
          onSelect={handleSelect}
          defaultActiveKey={1}
        >
          <Nav.Item>
            <Nav.Link eventKey={1}>
              {getT(translationKeys.order_info_section_label)}
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey={2}>Order Products</Nav.Link>
        </Nav.Item> */}
          <Nav.Item>
            <Nav.Link eventKey={2}>
              {getT("order_billing_section_label")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={3}>
              {getT("order_shipping_section_label")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={4}>
              {getT("order_tracking_section_label")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="orderDetailBody">
          {activeTab === 1 && (
            <>
              <div className="productItems">
                <p>
                  <b> {getT("products")}</b>
                </p>
                {order &&
                  order.products &&
                  order.products.map((o, index) => {
                    return (
                      <div className="productItem">
                        <p>
                          <span>{index + 1 + ": "}</span>
                          {o?.product?.name}
                        </p>
                        <p>
                          <b>{getT("price_label")}: </b>
                          {o?.product?.price + " " + currencyType}
                        </p>
                        <p>
                          <b> Quantity: </b>
                          {o?.quantity}
                        </p>
                        {getVariations(o.product_id, order) &&
                          getVariations(o.product_id, order).length > 0 && (
                            <>
                              {" "}
                              <p>
                                <b>{getT("variations_label")}</b>
                              </p>
                              <div className="variation_items">
                                {getVariations(o.product_id, order) &&
                                  getVariations(o.product_id, order).map(
                                    (v) => {
                                      return (
                                        <>
                                          <div className="variation_item">
                                            {v?.option?.name +
                                              " - " +
                                              v?.option?.value +
                                              currencyType}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </>
                          )}
                      </div>
                    );
                  })}
              </div>
              {/* <p>
                  <b>{getT("tax-country_label")}: </b>
                  {order?.taxCountry}
                </p>
                <p>
                  <b>{getT("total_price_with_out_tax_label")}: </b>
                  {getTotalPrice(false, order) + currencyType}
                </p> */}
              <p className="px-3">
                <b>{getT("total_price")}: </b>
                {getTotalPrice(
                  true,
                  order,
                  order?.taxCountry === "italy" ? 0.22 : 0
                ) + currencyType}
              </p>
            </>
          )}

          {activeTab === 2 && (
            <>
              <AddressInfo info={order?.billing} />
            </>
          )}
          {activeTab === 3 && (
            <>
              <AddressInfo
                info={
                  order?.is_shipping_same ? order?.billing : order?.shipping
                }
              />
            </>
          )}
          {activeTab === 4 && (
            <>
              <p className="p-2">
                <b>{getT("tracking_details")}: </b>
                {order?.tracking_no}
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
}

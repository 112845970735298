import React, { useEffect, useState } from "react";
import MainWrapper from "./pages/MainWrapper";
import Cart from "./pages/ecom/cart";
import { getT, translationKeys } from "./admin/utils";
import { useCategoryQuery } from "./queries/categories";
import Product from "./pages/ecom/Product";
import { getCustomerToken } from "./utils";
import { useGeneralProductQuery } from "./queries/products";
import axios from "axios";
import { apiUrl } from "./config/paths";
import { toast } from "react-toastify";
import { updateOrderProductsData } from "./utils/helpers";
import useScreenSize from "./components/useScreenSize";
import { useAuth } from "./authContext";
import Navigation from "./components/Navigation";
import WrapperModal from "./components/ModalWrapper";
import { FaFilter } from "react-icons/fa";

export default function Root({
  showHeader = true,
  editId = null,
  topBar = false,
}) {
  const [initialId, setInitialId] = useState(
    localStorage.getItem("orderId") ? localStorage.getItem("orderId") : null
  );
  const [showModal, setShowModal] = useState(false);
  const [cats, setCats] = useState([]);
  const [selectedCats, setSelectedCats] = useState([]);
  const [products, setProducts] = useState([]);
  const { data, isIdle, isLoading, isError, error } = useGeneralProductQuery();
  const {
    isCustomerAuthenticated,
    customerInfo,
    order,
    updateOrderInfo,
    toggleLoginModal,
  } = useAuth();
  const {
    data: catData,
    isIdle: catIdle,
    isLoading: catLoading,
    isError: catError,
  } = useCategoryQuery();
  const isMobile = useScreenSize(768);

  useEffect(() => {
    if (catData && !catLoading && catData.items) {
      setCats(catData?.items);
    }
  }, [catLoading, catData]);

  useEffect(() => {
    if (data && !isLoading && data.items) {
      setProducts(data.items);
    }
  }, [isLoading, data]);

  // getting previous data
  useEffect(() => {
    if (!initialId) {
      createRowAndFetchId();
      //console.log("create a id for to track ");
    } else {
      readRequestData();
    }
  }, [initialId]);

  useEffect(() => {
    if (order && order.products) {
      onOrderChange();
    }
  }, [order]);

  // Function to create a row and get the ID
  const readRequestData = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/cusOrder/" + initialId);
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.order;
        updateOrderInfo(initialData);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error reading later");
    }
  };
  const createRowAndFetchId = async () => {
    try {
      const response = await axios.post(apiUrl + "/api/cusOrder", {
        status: 0,
      });
      if (
        response.status === 201 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        localStorage.setItem("orderId", response.data.order.id);
        setInitialId(response.data.order.id);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error creating row:", error);
      //toast.error("try again later");
    }
  };

  const onOrderChange = async () => {
    let ids = [];

    if (order?.products && order.products.length > 0) {
      order.products.forEach((p) => {
        ids.push({ id: p.product_id, q: p.quantity });
      });
    }

    let res = await updateOrderProductsData({
      product_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  const itemExists = (product) => {
    return order?.products && order.products.length > 0
      ? order.products.find((item) => item?.product_id === product.id)
      : false;
  };

  const onQuanityChange = (q, product) => {
    //let prodInputEle = document.getElementById("product_" + product.id);
    if (q >= 1) {
      if (itemExists(product)) {
        updateOrderInfo((prevCart) => ({
          ...prevCart,
          products: prevCart.products.map((p) => {
            if (p.product_id === product.id) {
              p.quantity = q;
            }
            return p;
          }),
        }));
      }
    } else {
      updateOrderInfo((prevCart) => ({
        ...prevCart,
        products: prevCart.products.filter((p) => p.product_id !== product.id),
      }));
      //prodInputEle.value = 1;
      //alert("quantity must be greater than 1");
    }
  };

  const onAdd = async (product) => {
    if (getCustomerToken()) {
      //let prodInputEle = document.getElementById("product_" + product.id);
      if (itemExists(product)) {
        updateOrderInfo((prevCart) => ({
          ...prevCart,
          products: prevCart.products.filter(
            (p) => p.product_id !== product.id
          ),
        }));
        //prodInputEle.value = 1;
      } else {
        updateOrderInfo((prevCart) => ({
          ...prevCart,
          products: [
            ...prevCart.products,
            {
              order_id: order.id,
              product_id: product.id,
              quantity: 1,
              product: product,
            },
          ],
        }));
      }
    } else {
      //toast.error("kindly login before you add product to the cart");
      toggleLoginModal(true);
    }
  };

  const toggleSelect = (cat) => {
    if (selectedCats.includes(cat)) {
      setSelectedCats(
        selectedCats.filter((selectedCat) => selectedCat !== cat)
      );
    } else {
      setSelectedCats([...selectedCats, cat]);
    }
  };

  return (
    <>
      <Cart onUpdateQuantity={onQuanityChange} />
      <WrapperModal
        show={showModal}
        title={getT("filter_title")}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        {!catLoading ? (
          <div className="catList">
            {cats &&
              cats.map((c) => {
                return (
                  <div
                    className={
                      "catListItem " +
                      (selectedCats.includes(c.id) ? "active" : "")
                    }
                    onClick={() => {
                      toggleSelect(c.id);
                    }}
                  >
                    {c.name}
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="p-4">{getT(translationKeys.pleaseWait)}</div>
        )}
      </WrapperModal>
      <MainWrapper
        mainClass="dynamicPage root_page"
        showHeader={showHeader}
        showChatBtn={false}
        topBar={false}
        showFooter={isMobile ? false : true}
      >
        <div className="infoWrapper">
          {/*  <div
            className="filter_bar"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <span>Filter</span>
            <FaFilter />
          </div> */}

          {!catLoading ? (
            <div className="catList">
              {cats &&
                cats.map((c) => {
                  return (
                    <div
                      className={
                        "catListItem " +
                        (selectedCats.includes(c.id) ? "active" : "")
                      }
                      onClick={() => {
                        toggleSelect(c.id);
                      }}
                    >
                      {c.name}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="p-4">{getT(translationKeys.pleaseWait)}</div>
          )}

          <div className="cartContainer">
            {!isLoading ? (
              <div className="e_prod_list">
                {products &&
                  order &&
                  products.map((p) => {
                    return (
                      <Product
                        info={p}
                        onAdd={onAdd}
                        itemExists={itemExists}
                        onQuanityChange={onQuanityChange}
                        order={order}
                        selectedCats={selectedCats}
                      />
                    );
                  })}
              </div>
            ) : (
              <div className="p-4">{getT(translationKeys.pleaseWait)}</div>
            )}
          </div>
        </div>
        {isMobile && isCustomerAuthenticated && <Navigation />}
      </MainWrapper>
    </>
  );
}

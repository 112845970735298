import React, { useEffect, useState } from "react";
import { footerLogos, getDynamicMenuLink } from "../utils";
import { getT, translationKeys } from "../admin/utils";
import { useMenuQuery } from "../queries/Menus";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../authContext";

export default function Footer({ noTopBar = true }) {
  const [menuList, setMenuList] = useState([]);
  const { data, isIdle, isLoading, isError, error } = useMenuQuery();
  const navigate = useNavigate();
  const { generalSettings } = useAuth();

  useEffect(() => {
    if (data && !isLoading && data.items) {
      let items = data.items.filter((t) => t.type === "footer");
      setMenuList(items);
    }
  }, [isLoading, data]);

  return (
    <div className={"no-print footer " + (!noTopBar ? "noTop" : "")}>
      {/* noTopBar && (
        <div className="topFotter">
          {footerLogos.map((l, index) => {
            return <img key={index} src={l.img} alt={l.name} />;
          })}
        </div>
      ) */}
      <div className="bottomFooter">
        <div className="copyRight">
          {generalSettings && generalSettings.copyRight
            ? generalSettings.copyRight
            : getT(translationKeys.copyRightLabel)}{" "}
          &copy; {new Date().getFullYear() + " "}{" "}
        </div>
        <div className="footerItems">
          {menuList &&
            menuList.map((m, index) => {
              return (
                <div
                  className="footerItem cursor"
                  key={index}
                  onClick={() => {
                    if (m.link_type === "internal") {
                      navigate(getDynamicMenuLink(m), {
                        replace: true,
                      });
                    }
                    if (m.link_type === "external") {
                      window.open(m?.link, "_blank");
                    }
                  }}
                >
                  {m?.name && m.name ? m.name : getDynamicMenuLink(m, "name")}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

import { useAuth } from "../authContext";
import CustomInput from "./forms/utils/CustomInput";
import { BsImage } from "react-icons/bs";

import {
  useCreateSettingMutation,
  useSettingsQuery,
  useUpdateSettingMutation,
} from "../queries/Settings";
import { getFromLocalStorage } from "../utils/helpers";
import { getT, translationKeys } from "./utils";
import WrapperModal from "../components/ModalWrapper";
import MediaPicker from "./MediaPicker";

const EventForm = ({ onEdit = false, editId = null, onClose = () => {} }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const { data, isIdle, isLoading, isError, error } = useSettingsQuery(
    editId,
    editId ? true : false
  );

  const [locale, setLocale] = useState(getFromLocalStorage("lng"));

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateSettingMutation)
    : (mutationHook = useCreateSettingMutation);
  const mutation = mutationHook();

  const newSchema = {
    bat_capacity: Yup.string().required("bat capacity is required"),
    bat_price: Yup.string().required("bat price is required"),
    ftv_price: Yup.string().required("ftv price is required"),
    panel_height: Yup.string().required("panel height is required"),
    panel_width: Yup.string().required("panel width is required"),
    panel_capacity: Yup.string().required("panel capacity is required"),
    default_discount: Yup.string().required("default discount is required"),
  };

  const editSchema = {
    //description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.settings);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.settings &&
        mutation.data.data.settings.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      default_discount: values?.default_discount,
      panel_width: values?.panel_width,
      panel_height: values?.panel_height,
      panel_capacity: values?.panel_capacity,
      ftv_price: values?.ftv_price,
      bat_price: values?.bat_price,
      bat_capacity: values?.bat_capacity,
      general: {
        copyRight: values.copyRight,
        logo_val: values.logo_val,
      },
    };
    console.log(values);

    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  const onImageUpate = (val) => {
    if (val !== "") {
      console.log(val);
      if (formikRef && formikRef.current) {
        formikRef.current.setFieldValue("logo_val", val);
      }
    }
    setShowModal(!showModal);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <div className="EventForm">something went wrong try again later</div>
    );
  }

  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.media)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <MediaPicker onSelected={onImageUpate} sFile={selectedFile} />
      </WrapperModal>
      <Container fluid className="EventForm">
        <h4>{getT(translationKeys.language)}</h4>
        <Row>
          <Col>
            <select
              className="form-select"
              onChange={(e) => {
                setLocale(e.target.value);
                localStorage.setItem("lng", e.target.value);
                window.location.reload();
              }}
              value={locale}
            >
              <option value={"it"}>it</option>
              <option value={"en"}>en</option>
            </select>
          </Col>
        </Row>
        <h4 className="my-2">{getT(translationKeys.settings)} </h4>
        <div className="leadFormInner">
          {(editData && onEdit) || !onEdit ? (
            <Formik
              initialValues={{
                default_discount: editData?.default_discount ?? 10,
                panel_width: editData?.panel_width ?? 1.2,
                panel_height: editData?.panel_height ?? 1.9,
                panel_capacity: editData?.panel_capacity ?? 0.425,
                ftv_price: editData?.ftv_price ?? 1000,
                bat_price: editData?.bat_price ?? 2000,
                bat_capacity: editData?.bat_capacity ?? 5,
                copyRight: editData?.general?.copyRight,
                logo_val: editData?.general?.logo_val,
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col md={12}>
                      <label>
                        <span>image source</span>
                        <span>
                          <BsImage
                            onClick={() => {
                              setShowModal(!showModal);
                            }}
                            fontSize={25}
                            className="p-1 cursor"
                          />
                        </span>
                      </label>
                      <CustomInput
                        label=""
                        defaultValue={values?.general?.logo_val}
                        name="logo_val"
                      />
                    </Col>
                    <Col md={12}>
                      <CustomInput
                        label={getT(translationKeys.copyRight)}
                        defaultValue={values?.general?.copyRight}
                        name="copyRight"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <button
                        type="submit"
                        disabled={submittable}
                        className="btn btn-success me-2"
                      >
                        {getT(translationKeys.submit)}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </div>

        <div className="langOptions"></div>
      </Container>
    </>
  );
};

export default EventForm;

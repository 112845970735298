import moment from "moment";
import { getFromLocalStorage } from "../utils/helpers";
import axios from "axios";
import paths, { apiUrl } from "../config/paths";

let translations = [];

export const updateTranslationList = (data) => {
  translations = data;
};

export const loadTranslations = async () => {
  try {
    if (translations && translations.length === 0) {
      const response = await axios.get(apiUrl + "/api/translation", {
        headers: {
          Authorization: getFromLocalStorage("userToken"),
        },
      });
      if (response.data && response.data.items) {
        translations = response.data.items;
      }
    }
  } catch (error) {
    console.error("Error loading translations:", error);
  }
};

export const getT = (key) => {
  let lng = getFromLocalStorage("lng") ? getFromLocalStorage("lng") : "en";
  let out = key;

  if (translations && translations.length > 0) {
    translations.forEach((t) => {
      if (
        t.name.toLowerCase() &&
        key &&
        key.toLowerCase() &&
        t.name.toLowerCase() === key.toLowerCase() &&
        t?.value[lng]
      ) {
        out = t?.value[lng];
      }
    });
  }
  return out;
};

export const translationKeys = {
  createdBy: "createdBy",
  dynamicforms: "dynamicforms",
  quoteInfo: "quoteInfo",
  order_label: "order_label",
  order_info_section_label: "order_info_section_label",
  payment_status_label: "payment_status_label",
  share: "share",
  points: "points",
  firstnamerequired: "firstnamerequired",
  lastnamerequired: "lastnamerequired",
  referral_share_label: "referral_share_label",
  referral_share_info_label: "referral_share_info_label",
  referral_system_label: "referral_system_label",
  referral_system_label_title: "referral_system_label_title",
  referral_system_label_text: "referral_system_label_text",
  link_type_required: "link_type_required",
  pages_menu_list_label: "pages_menu_list_label",
  page_not_found_label: "page_not_found_label",
  ticket_category_label: "ticket_category_label",
  ticket_select_administration_label: "ticket_select_administration_label",
  ticket_select_inspection_label: "ticket_select_inspection_label",
  ticket_select_practices_label: "ticket_select_practices_label",
  ticket_select_others_label: "ticket_select_others_label",
  ticket_select_category_placeholder_label:
    "ticket_select_category_placeholder_label",
  for_more_label: "for_more_label",
  do_you_want_to_pay_deposit_label: "do_you_want_to_pay_deposit_label",
  appointment_notification_create_label:
    "appointment_notification_create_label",
  appointment_notification_create_description_label:
    "appointment_notification_create_description_label",
  appointment_notification_update_label:
    "appointment_notification_update_label",
  appointment_notification_update_description_label:
    "appointment_notification_update_description_label",
  quote_notification_create_label: "quote_notification_create_label",
  quote_notification_create_description_label:
    "quote_notification_create_description_label",
  quote_notification_update_label: "quote_notification_update_label",
  quote_notification_update_description_label:
    "quote_notification_update_description_label",
  ticket_notification_create_label: "ticket_notification_create_label",
  ticket_notification_create_description_label:
    "ticket_notification_create_description_label",
  ticket_notification_update_description_label:
    "ticket_notification_update_description_label",
  ticket_notification_update_label: "ticket_notification_update_label",
  general_notification_create_label: "general_notification_create_label",
  general_notification_create_description_label:
    "general_notification_create_description_label",
  general_notification_update_label: "general_notification_update_label",
  general_notification_update_description_label:
    "general_notification_update_description_label",

  download: "download",
  select_10_and_more_label: "select_10_and_more_label",
  select_3_and_more_label: "select_3_and_more_label",
  select_5_and_more_label: "select_5_and_more_label",
  select_future_label: "select_future_label",
  induction_select_yes_label: "induction_select_yes_label",
  induction_select_no_label: "induction_select_no_label",
  induction_select_future_label: "induction_select_future_label",
  electric_car_select_yes_label: "electric_car_select_yes_label",
  electric_car_select_yes_with_more_label:
    "electric_car_select_yes_with_more_label",
  electric_car_select_no_label: "electric_car_select_no_label",
  electric_car_select_future_label: "electric_car_select_future_label",

  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  futuro: "futuro",
  owner_label: "owner_label",
  address_not_valid_label: "address_not_valid_label",
  selection_electric_placeholder: "selection_electric_placeholder",
  do_like_to_share_your_location_label: "do_like_to_share_your_location_label",
  energy_info_should_be_minimum_one: "energy_info_should_be_minimum_one",
  energy_info_required: "energy_info_required",
  mqt_minimum_one_required: "mqt_minimum_one_required",
  mqi_minimum_one_required: "mqi_minimum_one_required",
  comune_code: "comune_code",
  comune: "comune",
  mobile_verify_counter_help_label: "mobile_verify_counter_help_label",
  mobile_verify_submit_label: "mobile_verify_submit_label",
  fill_person_type_error: "fill_person_type_error",
  fill_construction_type_error: "fill_construction_type_error",
  are_you_sure_to_start_from_fresh_label:
    "are_you_sure_to_start_from_fresh_label",
  appointment_modify_request_label: "appointment_modify_request_label",
  are_you_sure_cancel_appointment_label:
    "are_you_sure_cancel_appointment_label",
  calender_date_modify: "calender_date_modify",
  appointment_table_status: "appointment_table_status",
  toggle_info_close_label: "toggle_info_close_label",
  toggle_info_open_label: "toggle_info_open_label",
  profile_codice_fiscale_label: "profile_codice_fiscale_label",
  profile_company_name_label: "profile_company_name_label",
  new_password: "new_password",
  ticket_modal_label: "ticket_modal_label",
  appointment_label: "appointment_label",
  notifications_title: "notifications_title",
  no_data_label: "no_data_label",
  customer: "customer",
  notifications: "notifications",
  request_sent_success_label: "request_sent_success_label",
  request_update_success_label: "request_update_success_label",
  are_you_sure_to_delete_your_roof_panel_label:
    "are_you_sure_to_delete_your_roof_panel_label",
  phone_country_code: "phone_country_code",
  mobile_number_not_valid: "mobile_number_not_valid",
  login_or_signup_modal_title: "login_or_signup_modal_title",
  mobile_verification_modal_title: "mobile_verification_modal_title",
  chat_title: "chat_title",
  fill_message_label: "fill_message_label",
  are_you_sure_to_close_chat_label: "are_you_sure_to_close_chat_label",
  are_you_sure_to_logout: "are_you_sure_to_logout",
  quote_payment_label: "quote_payment_label",
  quote_administrative_label: "quote_administrative_label",
  quote_fotovoltaic_module_label: "quote_fotovoltaic_module_label",
  quote_inverter_label: "quote_inverter_label",
  quote_battery_label: "quote_battery_label",
  quote_col_label: "quote_col_label",
  are_you_sure_label: "are_you_sure_label",
  quote_certification_label: "quote_certification_label",
  quote_configuration_label: "quote_configuration_label",
  quote_status_title: "quote_status_title",
  quote_status_sub_title: "quote_status_sub_title",
  calender_appointment_label: "calender_appointment_label",
  quotes_table_eye_label: "quotes_table_eye_label",
  quotes_table_pencil_label: "quotes_table_pencil_label",
  quotes_table_pdf_label: "quotes_table_pdf_label",
  quotes_table_euro_label: "quotes_table_euro_label",
  my_account_label: "my_account_label",
  quotes_table_header_title: "quotes_table_header_title",
  quotes_table_add_new_button_title: "quotes_table_add_new_button_title",
  quotes_table_id: "quotes_table_id",
  quotes_table_date: "quotes_table_date",
  quotes_table_ftv: "quotes_table_ftv",
  quotes_table_accumuliation: "quotes_table_accumuliation",
  quotes_table_col: "quotes_table_col",
  quotes_table_price: "quotes_table_price",
  tickets_table_header_title: "tickets_table_header_title",
  tickets_table_id: "tickets_table_id",
  tickets_table_date: "tickets_table_date",
  tickets_table_category: "tickets_table_category",
  tickets_table_title: "tickets_table_title",
  tickets_table_add_new_button_title: "tickets_table_add_new_button_title",
  appointment_table_header_title: "appointment_table_header_title",
  appointment_table_date: "appointment_table_date",
  appointment_table_hour: "appointment_table_hour",
  appointment_table_id: "appointment_table_id",
  appointment_table_title: "appointment_table_title",
  profile_info_label: "profile_info_label",
  notification_info_label: "notification_info_label",
  draw_info_label: "draw_info_label",
  login_info_label: "login_info_label",
  logout_info_label: "logout_info_label",
  lang_info_label: "lang_info_label",
  pointer_info_label: "pointer_info_label",
  delete_polygon_info_label: "delete_polygon_info_label",
  toggle_info_label: "toggle_info_label",
  rotate_info_label: "rotate_info_label",
  plus_zoom_info_label: "plus_zoom_info_label",
  minus_zoom_info_label: "minus_zoom_info_label",
  fillInclinationlabel: "fillInclinationlabel",
  people_count_required: "people_count_required",
  heat_pump_required: "heat_pump_required",
  selection_induction_placeholder: "selection_induction_placeholder",
  profile_consumption_title: "profile_consumption_title",
  selection_cold_placeholder: "selection_cold_placeholder",
  selection_heat_placeholder: "selection_heat_placeholder",
  selection_people_placeholder: "selection_people_placeholder",
  acLabel2: "acLabel2",
  dynamicSelects: "dynamicSelects",
  langRequired: '"langRequired',
  selectionLabel_riscaldamento: "selectionLabel_riscaldamento",
  selectionLabel_conviventi: "selectionLabel_conviventi",
  is_air_conditioners_pump_label: "is_air_conditioners_pump_label",
  fillPianifouriterralabel: "fillPianifouriterralabel",
  selectionLabel: "selectionLabel",
  idontknowTerraLabel: "idontknowTerraLabel",
  moduleSettings: "moduleSettings",
  template: "template",
  copyRight: "copyRight",
  notfound: "notfound",
  parent: "parent",
  subject: "subject",
  body: "body",
  subjectRequired: "subjectRequired",
  content: "content",
  contentRequired: "contentRequired",
  emailTemplates: "emailTemplates",
  forgetpwd_label: "forgetpwd_label",
  ticket_name: "ticket_name",
  no_items_label: "no_items_label",
  from: "from",
  codeRequired: "codeRequired",
  drawing_help_label: "drawing_help_label",
  to: "to",
  myOrders: "myOrders",
  appointments: "appointments",
  gse: "gse",
  loginError: "loginErrorLabel",
  link_type: "linkType",
  link: "link",
  notAutherized: "notautherized",
  logout: "logout",
  media: "Media",
  size: "size",
  alias: "alias",
  page: "page",
  shop: "shop",
  lang: "lang",
  permalink: "permalink",
  contactSearchLabel: "contactSearchLabel",
  subject: "subject",
  pages: "pages",
  menus: "menus",
  aboutLabelTop: "aboutLabelTop",
  aboutLabelBottom: "aboutLabelBottom",
  aboutLabelBottomDes: "aboutLabelBottomDes",
  aboutServiceName0: "aboutServiceName0",
  aboutServiceName1: "aboutServiceName1",
  aboutServiceName2: "aboutServiceName2",
  aboutServiceDes0: "aboutServiceDes0",
  aboutServiceDes1: "aboutServiceDes1",
  aboutServiceDes2: "aboutServiceDes2",
  guarantee_consultant_title: "guarantee_consultant_title",
  guarantee_installation_title: "guarantee_installation_title",
  guarantee_quality_title: "guarantee_quality_title",
  guarantee_tech_title: "guarantee_tech_title",
  guarantee_monitor_title: "guarantee_monitor_title",
  guarantee_termini_title: "guarantee_termini_title",
  guarantee_certficate_title: "guarantee_certficate_title",
  guarantee_bill_title: "guarantee_bill_title",
  guaranteeTitle: "guaranteeTitle",
  galleyTitle: "galleyTitle",
  contactTitle: "contactTitle",
  contactSubTitle: "contactSubTitle",
  infoTitle: "infoTitle",
  infoSubTitle: "infoSubTitle",
  infoProdTitle1: "infoProdTitle1",
  infoProdTitle2: "infoProdTitle2",
  infoProdTitle1des: "infoProdTitle1des",
  infoProdTitle3: "infoProdTitle3",
  infoProdTitle3des1: "infoProdTitle3des1",
  infoProdTitle3des2: "infoProdTitle3des2",
  infoProdTitle4: "infoProdTitle4",
  infoProdTitle4percent: "infoProdTitle4percent",
  infoProdTitle4des1: "infoProdTitle4des1",
  infoProdTitle4des2: "infoProdTitle4des2",
  infoProdTitle4des3: "infoProdTitle4des3",
  infoProdTitle4des4: "infoProdTitle4des4",
  infoProdTitle4des5: "infoProdTitle4des5",
  faqDes4_sub1: "faqDes4_sub1",
  faqDes4_sub2: "faqDes4_sub2",
  faqDes4_sub3: "faqDes4_sub3",
  faqDes4_sub4: "faqDes4_sub4",
  faqDes4_sub5: "faqDes4_sub5",
  faqDes4_sub6: "faqDes4_sub6",
  faqDes2: "faqDes2",
  faqDes3: "faqDes3",
  faqDes4: "faqDes4",
  faqDes5: "faqDes5",
  faqTitle: "faqTitle",
  faqTitle1: "faqTitle1",
  faqTitle2: "faqTitle2",
  faqTitle3: "faqTitle3",
  faqTitle4: "faqTitle4",
  faqTitle5: "faqTitle5",
  faqDes1: "faqDes1",
  faqDes1_sub1: "faqDes1_sub1",
  faqDes1_sub2: "faqDes1_sub2",
  faqDes1_sub3: "faqDes1_sub3",
  faqDes1_sub4: "faqDes1_sub4",
  faqDes1_sub5: "faqDes1_sub5",
  loginLabel: "loginLabel",
  loginSubLabel: "loginSubLabel",
  preventivo_number_label: "preventivo_number_label",
  copyRightLabel: "copyRightLabel",
  energy_note_label: "energy_note_label",
  energy_sub_label: "energy_sub_label",
  energy_title_label: "energy_title_label",
  home_type_required: "home_type_required",
  person_type_required: "person_type_required",
  mqt_required: "mqt_required",
  mqi_required: "mqi_required",
  selection_roof_label: "selection_roof_label",
  selection_roof_sub_label: "selection_roof_sub_label",
  private: "private",
  fillAllLabel: "fillAllLabel",
  sanitary_water_required: "sanitary_water_required",
  electric_car_required: "electric_car_required",
  company_name_required: "company_name_required",
  address_required: "address_required",
  zip_required: "zip_required",
  prov_required: "prov_required",
  phone_required: "phone_required",
  induction_hub_required: "induction_hub_required",
  air_conditioners_pump_required: "air_conditioners_pump_required",
  f1_required: "f1_required",
  f2_required: "f2_required",
  f3_required: "f3_required",
  quoteBtn: "quoteBtn",
  is_owner: "is_owner",
  sanitary_water_label: "sanitary_water_label",
  idontknow: "idontknow",
  company_name: "company_name",
  mqi: "mqi",
  mqt: "mqt",
  fidontknow: "fidontknow",
  personType: "personType",
  nameRequired: "nameRequired",
  surnameRequired: "surnameRequired",
  emailRequired: "emailRequired",
  mobileRequired: "mobileRequired",
  addressRequired: "addressRequired",
  zipRequired: "zipRequired",
  countryRequired: "countryRequired",
  cityRequired: "cityRequired",
  stateRequired: "stateRequired",
  passwordRequired: "passwordRequired",
  descriptionRequired: "descriptionRequired",
  startDateRequired: "startDateRequired",
  endDateRequired: "endDateRequired",
  fromRequired: "fromRequired",
  toRequired: "toRequired",
  emailError: "emailError",
  quoteRequired: "quoteRequired",
  nameesurname: "nameesurname",
  name: "name",
  password: "password",
  surname: "surname",
  email: "email",
  phone: "phone",
  address: "address",
  country: "country",
  company: "company",
  province: "province",
  profile: "profile",
  reset: "reset",
  search: "search",
  language: "language",
  settings: "settings",
  height: "height",
  width: "width",
  panelcapacity: "panelcapacity",
  ftvprice: "ftvprice",
  batprice: "batprice",
  batcapacity: "batcapacity",
  description: "description",
  zip: "zip",
  city: "city",
  state: "state",
  source: "source",
  back: "back",
  submit: "submit",
  it: "it",
  en: "en",
  addLabel: "addLabel",
  create: "create",
  update: "update",
  update_form_title: "update_form_title",
  delete: "delete",
  created: "created",
  updated: "updated",
  update_label: "update_label",
  status: "status",
  yes: "yes",
  no: "no",
  roles: "roles",
  pleaseWait: "pleaseWait",
  startDate: "startDate",
  endDate: "endDate",
  client: "client",
  quote: "quote",
  installers: "installers",
  notes: "notes",
  showmap: "showmap",
  showform: "showform",
  customerInformation: "customerInformation",
  position: "position",
  inclination: "inclination",
  inclinationLabel: "inclinationLabel", //Inclinazione tetto : degress(°)
  tilt: "tilt",
  consumption: "consumption", // consumo di energia
  tiltLabel: "tiltLabel", //" Adattamento moduli : degress(°)"
  f1: "F1",
  f2: "F2",
  f3: "F3",
  payment: "payment",
  Installation: "Installation",
  GSE: "GSE",
  discount: "Discount",
  batteryCount: "batteryCount",
  energyRequirements: "energyRequirements", //ANALISI DI FABBISOGNO ENERGETICO
  electricCarLabel: "electricCarLabel", //Electric car"
  colLabel: "colLabel", //Col
  personsLabel: "personsLabel", //Da quanti utenti è abitato l'immobile nel quale installare l'impianto fotovoltaico?*
  pumpLabel: "pumpLabel", //E' presente una pompa di calore? *
  acLabel: "acLabel", //Quanti climatizzatori sono presenti nell'immobile?*
  kichenLabel: "kichenLabel", //Utilizzi un piano ad induzione in cucina? *
  generalError: "generalError", //something went wrong try again later
  leadSearchLabel: "leadSearchLabel",
  quoteSearchLabel: "quoteSearchLabel",
  roleSearchLabel: "roleSearchLabel",
  translationSearchLabel: "translationSearchLabel",
  requestSearchLabel: "requestSearchLabel",
  clientsSearchLabel: "clientsSearchLabel",
  leads: "leads",
  quotes: "quotes",
  calender: "calender",
  clients: "clients",
  translations: "translations",
  logout: "logout",
  dashboard: "dashboard",
  requests: "requests",
  id: "id",
  date: "date",
  next: "next",
  previous: "previous",
  perpage: "perpage",
  role: "role",
  ftv: "ftv",
  bat: "bat",
  col: "col",
  finalPrice: "finalPrice",
  login: "login",
  cancel: "cancel",
  signup: "signup",
  whoweare: "whoweare",
  photovoltaicsystems: "photovoltaicsystems",
  gsepractices: "gsepractices",
  contacts: "contacts",
  locationBigTitle: "locationBigTitle",
  locationBigTitle2: "locationBigTitle2",
  locationSmallTitle: "locationSmallTitle",
  locationPlaceHolder: "locationPlaceHolder",
  privacy: "privacy",
  constructionType: "constructionType",
  single: "single",
  shed: "shed",
  condominium: "condominium",
  ground: "ground",
  Property: "Property",
  floorslabel: "floorslabel",
  squareMeter: "squareMeter",
  inclinationFormLabel: "inclinationFormLabel",
  markerLabelContent: "markerLabelContent",
  tickets: "tickets",
  ticketStatusLabel: "ticketStatusLabel",
  choose: "choose",
  history: "history",
  message: "message",
  send: "send",
  messageRequired: "messageRequired",
  createdByLabel: "createdByLabel",
  respondByLabel: "respondByLabel",
  type: "type",
  paymentFailedMessage: "paymentFailedMessage",
  paymentSuccessMessage: "paymentSuccessMessage",
  boldLabel: "boldLabel",
  is_shipping_same_required: "is_shipping_same_required",
  alignmentLabel: "alignmentLabel",
  variations: "variations",
  iva: "iva",
  productcategorylabel: "productcategorylabel",
  users: "users",
  userName: "userName",
  quantity: "quantity",
  phoneRequired: "phoneRequired",
  companyRequired: "companyRequired",
  is_shipping_same_label: "is_shipping_same_label",
  ivaRequired: "ivaRequired",
  socialSecurityRequired: "socialSecurityRequired",
  confirmPasswordRequired: "confirmPasswordRequired",
  shippingName: "shippingName",
  shippingSurname: "shippingSurname",
  shippingCodiceFiscale: "shippingCodiceFiscale",
  shippingZip: "shippingZip",
  shippingCity: "shippingCity",
  shippingState: "shippingState",
  shippingCountry: "shippingCountry",
  shippingPhone: "shippingPhone",
  z_order: "z_order",
  socialSecurity: "socialSecurity",
  orders: "orders",
  confirmPassword: "confirmPassword",
  expired: "expired",
  variation: "variation",
  categories: "categories",
  active: "active",
  value: "value",
  subType: "subType",
  typeRequired: "typerequired",
  valuerequired: "valuerequired",
  additionalunitpricerequired: "additionalunitpricerequired",
  additionalpricerequired: "additionalpricerequired",
  additionalunitprice: "additionalunitprice",
  additionalpricelabel: "additionalpricelabel",
  productinfotablabel: "productinfotablabel",
  productvariationstablabel: "productvariationstablabel",
  productparallaxtablabel: "productparallaxtablabel",
  product_description: "product_description",
  priceRequired: "priceRequired",
  fullpriceRequired: "fullpriceRequired",
  title: "title",
  code: "code",
  subtitle: "subtitle",
  products: "products",
  price: "price",
  fullPrice: "fullPrice",
  shipping: "shipping",
  sales: "sales",
  startOffer: "startOffer",
  endOffer: "endOffer",
};

export const leadColumns = [
  { Header: getT("lead_table_col_id"), accessor: "id" },
  { Header: getT("lead_table_col_date"), accessor: "date" },
  { Header: getT("lead_table_col_name"), accessor: "name" },
  { Header: getT("lead_table_col_add"), accessor: "add" },
  { Header: getT("lead_table_col_province"), accessor: "comune" },
  { Header: getT("lead_table_col_phone"), accessor: "phone" },
  { Header: getT("lead_table_col_email"), accessor: "email" },
  { Header: getT("lead_table_col_type"), accessor: "type" },
  { Header: "", accessor: "action" },
];

export const contactColumns = [
  { Header: getT("contact_table_col_id"), accessor: "id" },
  { Header: getT("contact_table_col_date"), accessor: "date" },
  { Header: getT("contact_table_col_name"), accessor: "name" },
  { Header: getT("contact_table_col_address"), accessor: "add" },
  { Header: getT("contact_table_col_province"), accessor: "comune" },
  { Header: getT("contact_table_col_phone"), accessor: "phone" },
  { Header: getT("contact_table_col_email"), accessor: "email" },
  { Header: getT("contact_table_col_source"), accessor: "source" },
  { Header: "", accessor: "action" },
];

export const dynamicSelectColumns = [
  { Header: getT(translationKeys.id), accessor: "id" },
  { Header: getT(translationKeys.date), accessor: "date" },
  { Header: getT(translationKeys.name), accessor: "name" },
  { Header: getT(translationKeys.value), accessor: "value" },
  { Header: getT(translationKeys.lang), accessor: "lang" },
  { Header: getT(translationKeys.status), accessor: "status" },
  { Header: "", accessor: "action" },
];

export const emailTemplateColumns = [
  { Header: getT("email_template_table_col_id"), accessor: "id" },
  { Header: getT("email_template_table_col_date"), accessor: "date" },
  { Header: getT("email_template_table_col_name"), accessor: "name" },
  { Header: "", accessor: "action" },
];

export const dynamicFormsColumns = [
  { Header: getT("dynamicforms_table_col_id"), accessor: "id" },
  { Header: getT("dynamicforms_table_col_date"), accessor: "date" },
  { Header: getT("dynamicforms_table_col_source"), accessor: "source" },
  { Header: "", accessor: "action" },
];

export const categoryColumns = [
  { Header: getT("categories_table_col_id"), accessor: "id" },
  { Header: getT("categories_table_col_name"), accessor: "name" },
  { Header: getT("categories_table_col_active"), accessor: "active" },
  { Header: getT("categories_table_col_z_order"), accessor: "z_order" },
  { Header: "", accessor: "action" },
];

export const pageColumns = [
  { Header: getT("pages_table_col_id"), accessor: "id" },
  { Header: getT("pages_table_col_parent"), accessor: "parent" },
  { Header: getT("pages_table_col_permalink"), accessor: "permalink" },
  { Header: getT("pages_table_col_lang"), accessor: "lang" },
  { Header: getT("pages_table_col_name"), accessor: "name" },
  { Header: getT("pages_table_col_status"), accessor: "status" },
  { Header: "", accessor: "action" },
];

export const clientsColumns = [
  { Header: getT("clients_table_col_id"), accessor: "id" },
  { Header: getT("clients_table_col_name"), accessor: "name" },
  { Header: getT("clients_table_col_role"), accessor: "role" },
  { Header: getT("clients_table_col_status"), accessor: "status" },
  { Header: getT("clients_table_col_email"), accessor: "email" },
  { Header: getT("clients_table_col_company"), accessor: "company" },
  { Header: "", accessor: "action" },
];

export const rolesColumns = [
  { Header: getT(translationKeys.id), accessor: "id" },
  { Header: getT(translationKeys.name), accessor: "name" },
  { Header: "", accessor: "action" },
];

export const menuColumns = [
  { Header: getT("menu_table_col_id"), accessor: "id" },
  { Header: getT("menu_table_col_type"), accessor: "type" },
  { Header: getT("menu_table_col_linkType"), accessor: "linkType" },
  { Header: getT("menu_table_col_alias"), accessor: "alias" },
  { Header: getT("menu_table_col_name"), accessor: "name" },
  { Header: getT("menu_table_col_status"), accessor: "status" },
  { Header: "", accessor: "action" },
];

export const notificationColumns = [
  { Header: getT("notification_table_col_id"), accessor: "id" },
  { Header: getT("notification_table_col_type"), accessor: "type" },
  { Header: getT("notification_table_col_status"), accessor: "status" },
  { Header: "", accessor: "action" },
];

export const ticketColumns = [
  { Header: getT("tickets_table_col_id"), accessor: "id" },
  { Header: getT("tickets_table_col_name"), accessor: "name" },
  { Header: getT("tickets_table_col_created_by"), accessor: "createdBy" },
  { Header: getT("tickets_table_col_created_status"), accessor: "status" },
  { Header: "", accessor: "action" },
];

export const translateColumns = [
  { Header: getT("translate_table_col_id"), accessor: "id" },
  { Header: getT("translate_table_col_name"), accessor: "name" },
  { Header: getT("translate_table_col_it"), accessor: "it" },
  { Header: getT("translate_table_col_en"), accessor: "en" },
  { Header: "", accessor: "action" },
];

export const quoteColumns = [
  { Header: getT("quotes_table_col_date"), accessor: "date" },
  { Header: getT("quotes_table_col_id"), accessor: "id" },
  { Header: getT("quotes_table_col_name"), accessor: "name" },
  { Header: getT("quotes_table_col_province"), accessor: "comune" },
  { Header: getT("quotes_table_col_phone"), accessor: "phone" },
  { Header: getT("quotes_table_col_email"), accessor: "email" },
  { Header: getT("quotes_table_col_ftv"), accessor: "ftv" },
  { Header: getT("quotes_table_col_bat"), accessor: "bat" },
  { Header: getT("quotes_table_col_col"), accessor: "col" },
  { Header: getT("quotes_table_col_discount"), accessor: "discount" },
  { Header: getT("quotes_table_col_final_price"), accessor: "price" },
  { Header: getT("quotes_table_col_gse"), accessor: "gse" },
  { Header: getT("quotes_table_col_installation"), accessor: "install" },
  { Header: getT("quotes_table_col_payment"), accessor: "payment" },
  { Header: "", accessor: "action" },
];

export const isDateBetween = (startDate, endDate, checkDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const check = new Date(checkDate);

  return start <= check && check <= end;
};

export const abitiOptions = [
  {
    name: "1-2",
    value: "2",
  },
  {
    name: "3-4",
    value: "4",
  },
  {
    name: "5-6",
    value: "6",
  },
  {
    name: "7-8",
    value: "8",
  },
  {
    name: "Piu di 9",
    value: "n",
  },
];

export const inductionOptions = [
  {
    name: "induction_select_yes_label",
    value: "si",
  },
  {
    name: "induction_select_no_label",
    value: "no",
  },
  {
    name: "induction_select_future_label",
    value: "futuro",
  },
];

export const ticketCategoryOptions = [
  {
    name: "adminstration",
    value: "adminstration",
  },
  /*{
    name: "ticket_select_inspection_label",
    value: "inspection",
  },*/
  {
    name: "orders",
    value: "orders",
  },
  {
    name: "others",
    value: "others",
  },
];

export const infoOptions = [
  {
    name: "select_yes_label",
    value: "si",
  },
  {
    name: "select_no_label",
    value: "no",
  },
  {
    name: "select_future_label",
    value: "futuro",
  },
];

export const electric_car_Options = [
  {
    name: "electric_car_select_yes_label",
    value: "si",
  },
  {
    name: "electric_car_select_yes_with_more_label",
    value: "n",
  },
  {
    name: "electric_car_select_no_label",
    value: "no",
  },
  {
    name: "electric_car_select_future_label",
    value: "futuro",
  },
];

export const heatingOptions = [
  {
    name: "mpianto a gass",
    value: "gas_system",
  },
  {
    name: "pompa di calore",
    value: "heat_pump",
  },
  {
    name: " stufa a pellet",
    value: "pellet_stove",
  },
  {
    name: "camino",
    value: "chimney",
  },
  {
    name: "other",
    value: "other",
  },
  {
    name: "nessuno riscaldamento",
    value: "no_heating",
  },
];

export const airConditionerOptions = [
  {
    name: "1",
    value: 1,
  },
  {
    name: "2",
    value: 2,
  },
  {
    name: "3",
    value: 3,
  },
  {
    name: "select_3_and_more_label",
    value: "n",
  },
  {
    name: "select_future_label",
    value: "futuro",
  },
];

export const eventStatusList = [
  {
    name: "In attesa",
    value: 1,
    color: "orange",
  },
  {
    name: "Rimandato",
    value: 2,
    color: "blue",
  },
  {
    name: "Annullato",
    value: 3,
    color: "red",
  },
  {
    name: "Completato",
    value: 4,
    color: "green",
  },
];

export const paymentStatusOptions = [
  {
    name: "Non eseguito",
    value: 0,
    color: "yash_clr",
  },
  {
    name: "In attesa",
    value: 1,
    color: "orange_clr",
  },
  {
    name: "Parziale",
    value: 2,
    color: "orange_green_clr",
  },
  {
    name: "Eseguito",
    value: 3,
    color: "green_clr",
  },
];

export const langOptions = [
  {
    name: "italian",
    value: "it",
  },
  {
    name: "english",
    value: "en",
  },
];

export const CUSTOMERROLE = "customer";
export const OPERATORROLE = "operator";
export const ADMINROLE = "admin";
export const INSTALLERROLE = "installer";

// formulas

export const getPanelsCount = (roofArea, w, h) => {
  let panelArea = parseFloat(w) * parseFloat(h);
  //let count = parseFloat(roofArea / panelArea);
  //let panelCapacity = 0.425;
  //return count > 0 ? count * panelCapacity : 0;
  return parseFloat(roofArea / panelArea);
};

export const getPercentageByType = (val, type) => {
  let percent = 1;

  if (type === "peopleCount") {
    switch (val) {
      case "1":
        percent = 10;
        break;
      case "2":
        percent = 10;
        break;
      case "3":
        percent = 10;
        break;
      case "4":
        percent = 10;
        break;
      case "5":
        percent = 10;
        break;
      case "6":
        percent = 15;
        break;
      case "8":
        percent = 20;
        break;
      case "n":
        percent = 20;
        break;

      default:
        percent = 1;
        break;
    }
  }

  if (type === "inductionHub" || type === "col") {
    switch (val) {
      case "si":
        percent = 5;
        break;
      case "futuro":
        percent = 3;
        break;
      default:
        percent = 1;
        break;
    }
  }

  if (type === "heatPump") {
    switch (val) {
      case "futuro":
        percent = 5;
        break;
      case "n":
        percent = 10;
        break;
      case "5":
        percent = 10;
        break;
      case "4":
        percent = 10;
        break;
      case "3":
        percent = 3;
        break;
      case "2":
        percent = 3;
        break;
      default:
        percent = 1;
        break;
    }
  }

  if (type === "ac") {
    switch (val) {
      case "futuro":
        percent = 5;
        break;
      case "n":
        percent = 10;
        break;
      case "2":
        percent = 3;
        break;
      case "3":
        percent = 3;
        break;
      default:
        percent = 1;
        break;
    }
  }

  if (type === "electricCar") {
    switch (val) {
      case "futuro":
        percent = 5;
        break;
      case "n":
        percent = 10;
        break;
      case "si":
        percent = 3;
        break;
      default:
        percent = 1;
        break;
    }
  }

  return percent;
};

export const formatMysqlDate = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
};

export const formatMysqlDateToNormal = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY hh:mm:ss A");
};

export const formatDateInfo = (dateString) => {
  const date = moment(dateString, "YYYY-MM-DD HH:mm:ss");
  const now = moment();
  const daysDiff = now.diff(date, "days");

  if (daysDiff === 0) {
    return date.format("DD/MM/YYYY hh:mm:ss A") + " (" + date.fromNow() + ")";
  } else {
    return date.format("DD/MM/YYYY hh:mm:ss A");
  }
};

export const ticketStatusOptions = [
  {
    name: "open",
    value: 1,
  },
  {
    name: "closed",
    value: 2,
  },
];

export const templateOptions = [
  /*{
    name: "admin quote email template",
    value: "adminquotetemplate",
  },
  {
    name: "customer quote email template",
    value: "customerquotetemplate",
  },*/
  {
    name: "customer appointment email template",
    value: "customerappointmenttemplate",
  },
  {
    name: "customer ticket email template",
    value: "customertickettemplate",
  },
  {
    name: "forgot  password email template",
    value: "forgetpassword",
  },
  {
    name: "update password email template",
    value: "updatepassword",
  },
];

export const componentOptions = [
  {
    name: "header",
    value: "header",
  },
  {
    name: "image with text",
    value: "imagewithtext",
  },
  {
    name: "gallery",
    value: "gallery",
  },
  {
    name: "accordion",
    value: "accordion",
  },
  {
    name: "form",
    value: "form",
  },
  {
    name: "custom form",
    value: "customform",
  },
];

export const productColumns = [
  { Header: getT("product_table_col_id"), accessor: "id" },
  { Header: getT("product_table_col_code"), accessor: "name" },
  { Header: getT("product_table_col_price"), accessor: "price" },
  { Header: getT("product_table_col_full_price"), accessor: "fullPrice" },
  { Header: getT("product_table_col_shipping"), accessor: "shipping" },
  { Header: getT("product_table_col_sales"), accessor: "sales" },
  { Header: "", accessor: "action" },
];

export const orderColumns = [
  { Header: getT("order_table_col_id"), accessor: "id" },
  //{ Header: getT(translationKeys.name), accessor: "name" },
  //{ Header: getT(translationKeys.quantity), accessor: "quantity" },
  { Header: getT("order_table_col_status"), accessor: "status" },
  { Header: getT("order_table_col_name"), accessor: "userName" },
  { Header: "", accessor: "action" },
];
export const parallaxTypes = [
  {
    name: "block",
    value: "block",
  },
  {
    name: "gallery",
    value: "gallery",
  },
];

export const variationTypes = [
  {
    name: "variation",
    value: "variation",
  },
  {
    name: "shipping",
    value: "shipping",
  },
];

export const subVariationTypes = [
  {
    name: "capacity",
    value: "capacity",
  },
  {
    name: "color",
    value: "color",
  },
  {
    name: "model",
    value: "model",
  },
];

export const currencyType = "₹";

export const getVariations = (id, order) => {
  let items = [];
  if (order?.variations && order.variations.length > 0) {
    for (let index = 0; index < order.variations.length; index++) {
      if (
        order.variations[index].option &&
        order.variations[index].option?.product_id === id
      ) {
        items.push(order.variations[index]);
      }
    }
  }
  return items;
};

export const getTotalPrice = (withTax = false, order, taxVal = 0) => {
  let finalPrice = 0;

  if (order?.products && order.products.length > 0) {
    order.products.forEach((p) => {
      finalPrice =
        finalPrice + parseFloat(p?.product?.price) * parseFloat(p?.quantity);
      let vitems = getVariations(p?.product_id, order);
      if (vitems && vitems.length > 0) {
        vitems.forEach((v) => {
          finalPrice = finalPrice + parseFloat(v?.option?.value);
        });
      }
    });
  }

  //console.log(order);

  if (withTax) {
    let tax = finalPrice * taxVal;
    finalPrice = finalPrice + tax;
  }

  return finalPrice;
};

export const moduleOptions = (type = "options") => {
  let options = [
    /* {
      name: getT(translationKeys.quotes),
      path: paths.myQuotesPath,
      value: "quotes",
    },
    {
      name: getT(translationKeys.appointments),
      path: paths.appointments,
      value: "appointments",
    },
  
    {
      name: getT(translationKeys.profile),
      path: paths.profilePath,
      value: "profile",
    },
    {
      name: getT(translationKeys.tickets),
      path: paths.myTicketsPath,
      value: "tickets",
    },
    {
      name: getT(translationKeys.myOrders),
      path: paths.myOrders,
      value: "orders",
    },*/
    {
      name: getT(translationKeys.shop),
      path: paths.shopPath,
      value: "shop",
    },
  ];

  if (type === "options") {
    return options;
  }
};

import React, { useEffect, useState } from "react";
import CustomListTable from "../../components/CustomListTable";
import paths, { apiUrl } from "../../config/paths";
import { getCustomerToken } from "../../utils";
import Loading from "../../components/Loading";
import { formatMysqlDate, getT, translationKeys } from "../../admin/utils";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import TicketWidgetModal from "./TicketWidgetModal";
import { useAuth } from "../../authContext";
import OrderInfoWidgetModal from "./orderInfoWidgetModal";

export default function OrdersWidget({ userId = -1 }) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState(null);
  const token = getCustomerToken();
  const navigate = useNavigate();
  const locationInfo = useLocation();
  const queryParams = new URLSearchParams(locationInfo.search);
  const idParam = queryParams.get("ticket");
  const { ticketModalStatus, setTicketModalStatus } = useAuth();

  const loadItems = async () => {
    fetch(apiUrl + "/api/getOrders/" + userId, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((res) => {
        if (res && res.items && res.items.length > 0) {
          setList(
            res.items.map((i, index) => {
              i["local_id"] = index + 1;
              i.created_at = formatMysqlDate(i.created_at);
              return i;
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error checking token status:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId && userId > 0) {
      if (token) {
        loadItems();
      }
    }
  }, [userId]);

  useEffect(() => {
    if (idParam > 0 && ticketModalStatus) {
      list.forEach((l) => {
        if (l.id == idParam) {
          setId(l.id);
          setShowModal(true);
          setTicketModalStatus(false);
        }
      });
    }
  }, [idParam, list, ticketModalStatus]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {showModal && (
        <OrderInfoWidgetModal
          cusId={userId}
          orderId={id}
          showModal={showModal}
          setShowModal={(val) => {
            setId(null);
            setShowModal(val);
          }}
        />
      )}
      <CustomListTable
        title={getT("orders_table_header_title")}
        btnTitle={getT("orders_table_add_new_button_title")}
        addBtn={false}
        lengendOptions={[
          { name: getT("orders_table_id"), value: "local_id" },
          {
            name: getT("orders_table_date"),
            value: "created_at",
          },
          {
            name: getT("orders_table_payment_status"),
            value: "payment_status",
            link: true,
            extraClass: "extra_link",
          },
        ]}
        listItems={list}
        onNew={() => {
          setId(null);
          setShowModal(true);
          //navigate(paths.newMyTicketsPath);
        }}
        onEdit={(data) => {
          if (data && data.id > 0) {
            setId(data?.id);
            setShowModal(true);
            //navigate("/myTickets/edit/" + data?.id);
          }
        }}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "./utils/CustomInput";
import { getT, translationKeys } from "../utils";
import { FaUser } from "react-icons/fa";
import { BsBuildingsFill, BsFillPersonFill } from "react-icons/bs";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserQuery,
} from "../../queries/users";
import { useAuth } from "../../authContext";
import { FaSquarePhone } from "react-icons/fa6";
import { FcGoogle } from "react-icons/fc";
import { BiSolidLock } from "react-icons/bi";
import { IoMdEyeOff } from "react-icons/io";
import { IoMdEye } from "react-icons/io";

const UpdatePasswordForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useUserQuery(
    editId,
    editId ? true : false
  );
  const { authCustomerLogout } = useAuth();
  const [pwd, setPwd] = useState("password");

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateUserMutation)
    : (mutationHook = useCreateUserMutation);
  const mutation = mutationHook();

  const newSchema = {
    //name: Yup.string().required("Nome is required"),
    //description: Yup.string().required("description is required"),
    password: Yup.string().required("password is required"),
  };

  const editSchema = {
    //description: Yup.string().required("description is required"),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading && data?.user) {
      setEditData(data?.user);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.user &&
        mutation.data.data.user.id > 0
      ) {
        onEdit ? toast.success("updated") : toast.success("created");
        authCustomerLogout();
        navigate(paths.homePath, { replace: true });
        window.location.reload();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error("try again later");
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      password: values.password,
    };
    if (onEdit) {
      delete finalData.name;
    }
    toast.warning("please wait..");
    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.rolesPath);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">something went wrong try again later</div>;
  }

  return (
    <Container fluid className="leadForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              email: editData?.email ?? "",
              password: "",
              loginType: editData?.login_type ? true : false,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ formik, values, setFieldValue }) => (
              <Form>
                <h5> {getT(translationKeys.update_form_title)}</h5>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.email}
                      name="email"
                      disabled
                      label={getT(translationKeys.email)}
                      labelStatus={false}
                      placeholder="Email"
                      isIcon={false}
                      iconData={<FcGoogle />}
                    />
                  </Col>

                  <Col sm={12} md={12} className="mt-2">
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.password}
                      name="password"
                      label={getT(translationKeys.password)}
                      labelStatus={false}
                      placeholder="Password"
                      isIcon={false}
                      type={pwd}
                      extraIcon={false}
                      extranIconData={
                        pwd === "password" ? (
                          <IoMdEyeOff
                            onClick={() => {
                              setPwd("text");
                            }}
                            className="pwdIcon cursor"
                          />
                        ) : (
                          <IoMdEye
                            onClick={() => {
                              setPwd("password");
                            }}
                            className="pwdIcon cursor"
                          />
                        )
                      }
                      iconData={<BiSolidLock />}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.update_label)}
                    </button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default UpdatePasswordForm;

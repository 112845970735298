import { getT, translationKeys } from "../admin/utils";
import paths from "./paths";

const apiUrlInternal = process.env.REACT_APP_API_URL;
export { apiUrlInternal as default };

export const menuList = [
  {
    name: translationKeys.dashboard,
    value: "dashboard",
    routeVal: "dashboard",
    path: paths.dashboardPath,
  },
  {
    name: translationKeys.orders,
    value: "orders",
    routeVal: "orders",
    path: paths.ordersPath,
  },
  {
    name: translationKeys.products,
    value: "products",
    routeVal: "products",
    path: paths.productsPath,
  },
  {
    name: translationKeys.categories,
    value: "categories",
    routeVal: "categories",
    path: paths.categoriesPath,
  },
  {
    name: translationKeys.pages,
    value: "pages",
    routeVal: "pages",
    path: paths.pagesPath,
  },
  /*{
    name: translationKeys.leads,
    value: "leads",
    routeVal: "leads",
    path: paths.leadsPath,
  },
  {
    name: translationKeys.quotes,
    value: "quotes",
    routeVal: "preventivi",
    path: paths.quotesPath,
  },*/
  {
    name: translationKeys.calender,
    value: "calender",
    routeVal: "calender",
    path: paths.calenderPath,
  },
  {
    name: translationKeys.clients,
    value: "clients",
    routeVal: "clienti",
    path: paths.clientsPath,
  },
  {
    name: translationKeys.settings,
    value: "settings",
    routeVal: "settings",
    path: paths.settingsPath,
  },
  {
    name: translationKeys.moduleSettings,
    value: "modules",
    routeVal: "modulesSettings",
    path: paths.moduleSettingsPath,
  },
  {
    name: translationKeys.translations,
    value: "translations",
    routeVal: "translations",
    path: paths.translationsPath,
  },
  {
    name: translationKeys.tickets,
    value: "tickets",
    routeVal: "tickets",
    path: paths.ticketsPath,
  },
  {
    name: translationKeys.emailTemplates,
    value: "emailTemplates",
    routeVal: "emailTemplates",
    path: paths.emailTemplatesPath,
  },
  {
    name: translationKeys.contacts,
    value: "contacts",
    routeVal: "contacts",
    path: paths.contactsPath,
  },
  {
    name: translationKeys.menus,
    value: "menus",
    routeVal: "menus",
    path: paths.menusPath,
  },
  {
    name: translationKeys.dynamicforms,
    value: "dynamicforms",
    routeVal: "dynamicforms",
    path: paths.dynamicFormsPath,
  },
  {
    name: translationKeys.notifications,
    value: "notifications",
    routeVal: "notifications",
    path: paths.notificationsPath,
  },
  /*{
    name: translationKeys.dynamicSelects,
    value: "dynamicSelects",
    routeVal: "dynamicSelects",
    path: paths.dynamicSelectsPath,
  },
  {
    name: "Roles",
    value: "roles",
    routeVal: "roles",
    path: paths.rolesPath,
  },*/
  {
    name: translationKeys.logout,
    value: "logout",
    path: "",
  },
];

export const operatorMenuList = [
  {
    name: translationKeys.dashboard,
    value: "dashboard",
    routeVal: "dashboard",
    path: paths.dashboardPath,
  },
  {
    name: translationKeys.leads,
    value: "leads",
    routeVal: "leads",
    path: paths.leadsPath,
  },
  {
    name: translationKeys.quotes,
    value: "quotes",
    routeVal: "preventivi",
    path: paths.quotesPath,
  },
  {
    name: translationKeys.clients,
    value: "clients",
    routeVal: "clienti",
    path: paths.clientsPath,
  },
  {
    name: translationKeys.tickets,
    value: "tickets",
    routeVal: "tickets",
    path: paths.ticketsPath,
  },
  {
    name: translationKeys.calender,
    value: "logout",
    path: "",
  },
];

export const userMenuList = [
  {
    name: translationKeys.requests,
    value: "requests",
    routeVal: "requests",
    path: paths.requestsPath,
  },
  {
    name: translationKeys.profile,
    value: "me",
    routeVal: "me",
    path: paths.mePath,
  },
  {
    name: translationKeys.calender,
    value: "calender",
    routeVal: "calender",
    path: paths.calenderPath,
  },
  {
    name: translationKeys.tickets,
    value: "tickets",
    routeVal: "tickets",
    path: paths.ticketsPath,
  },
  {
    name: translationKeys.logout,
    value: "logout",
    path: "",
  },
];

export const installerMenuList = [
  {
    name: translationKeys.calender,
    value: "calender",
    routeVal: "calender",
    path: paths.calenderPath,
  },
  {
    name: translationKeys.logout,
    value: "logout",
    path: "",
  },
];

export const chat_gpt_key = process.env.REACT_APP_CHAT_GPT_KEY;
export const chat_gpt_assistant_key =
  process.env.REACT_APP_CHAT_GPT_ASSISTANT_ID;

export const google_auth_id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const tmc_editor_key = process.env.REACT_APP_TMC_EDITOR_KEY;

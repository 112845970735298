import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import CustomInput from "../../admin/forms/utils/CustomInput";
import { getT, translationKeys } from "../../admin/utils";
import { updateOrderData } from "../../utils/helpers";
import { toast } from "react-toastify";

const Shipping = ({
  onEdit = false,
  editId = null,
  onBack = () => {},
  onMove = () => {},
  order = {},
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const navigate = useNavigate();
  let initialSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
    socialSecurity: Yup.string().required(
      getT(translationKeys.socialSecurityRequired)
    ),
    zip: Yup.string().required(getT(translationKeys.zipRequired)),
    city: Yup.string().required(getT(translationKeys.cityRequired)),
    state: Yup.string().required(getT(translationKeys.stateRequired)),
    country: Yup.string().required(getT(translationKeys.countryRequired)),
    phone: Yup.string().required(getT(translationKeys.phoneRequired)),
    //email: Yup.string().required(getT(translationKeys.emailRequired)),
    //password: Yup.string().required(getT(translationKeys.passwordRequired)),
    /*confirmPassword: Yup.string().required(
      getT(translationKeys.confirmPasswordRequired)
    ),*/
    //company: Yup.string().required(getT(translationKeys.companyRequired)),
    iva: Yup.string().required(getT(translationKeys.ivaRequired)),
    is_shipping_same: Yup.boolean(),
  };
  const [schema, setSchema] = useState(() => Yup.object().shape(initialSchema));

  let updateSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    surname: Yup.string().required(getT(translationKeys.surnameRequired)),
    socialSecurity: Yup.string().required(
      getT(translationKeys.socialSecurityRequired)
    ),
    zip: Yup.string().required(getT(translationKeys.zipRequired)),
    city: Yup.string().required(getT(translationKeys.cityRequired)),
    state: Yup.string().required(getT(translationKeys.stateRequired)),
    country: Yup.string().required(getT(translationKeys.countryRequired)),
    phone: Yup.string().required(getT(translationKeys.phoneRequired)),
    //email: Yup.string().required(getT(translationKeys.emailRequired)),
    //password: Yup.string().required(getT(translationKeys.passwordRequired)),
    /*confirmPassword: Yup.string().required(
      getT(translationKeys.confirmPasswordRequired)
    ),*/
    //company: Yup.string().required(getT(translationKeys.companyRequired)),
    iva: Yup.string().required(getT(translationKeys.ivaRequired)),
    is_shipping_same: Yup.boolean(),
    shippingName: Yup.string().required(getT(translationKeys.nameRequired)),

    shippingSurname: Yup.string().required(
      getT(translationKeys.surnameRequired)
    ),
    shippingCodiceFiscale: Yup.string().required(
      getT(translationKeys.socialSecurityRequired)
    ),
    shippingZip: Yup.string().required(getT(translationKeys.zipRequired)),
    shippingCity: Yup.string().required(getT(translationKeys.cityRequired)),
    shippingState: Yup.string().required(getT(translationKeys.stateRequired)),
    shippingCountry: Yup.string().required(
      getT(translationKeys.countryRequired)
    ),
    shippingPhone: Yup.string().required(getT(translationKeys.phoneRequired)),
    shippingCompany: Yup.string().required(
      getT(translationKeys.companyRequired)
    ),
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    let res = await updateOrderData(values);

    if (res.status) {
      // do something
      onMove();
    } else {
      //console.log("update failed");

      toast.error("try again later or email already exists");
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.translationsPath);
  };

  return (
    <Container fluid className="frontForm">
      <div className="frontFormInner">
        <div className="frontFormInnerInfo p-1">
          {(editData && onEdit) || !onEdit ? (
            <Formik
              initialValues={{
                checkShipping: false,
                name: order?.billing?.name,
                surname: order?.billing?.surname,
                socialSecurity: order?.billing?.socialSecurity,
                zip: order?.billing?.zip,
                city: order?.billing?.city,
                state: order?.billing?.state,
                phone: order?.billing?.phone,
                country: order?.billing?.country,
                email: "",
                password: "",
                confirmPassword: "",
                company: order?.billing?.company,
                iva: order?.billing?.iva,
                shippingName: order?.shipping?.name ?? "",
                shippingSurname: order?.shipping?.surname,
                shippingCity: order?.shipping?.city,
                shippingCodiceFiscale: order?.shipping?.socialSecurity,
                shippingCompany: order?.shipping?.company,
                shippingCountry: order?.shipping?.country,
                shippingPhone: order?.shipping?.phone,
                shippingState: order?.shipping?.state,
                shippingZip: order?.shipping?.zip,
                is_shipping_same:
                  order?.is_shipping_same && order.is_shipping_same
                    ? true
                    : false,
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, formik }) => (
                <Form>
                  <Row>
                    <h2>{getT("cart_address_title")}</h2>
                    <p>{getT("cart_address_description")}</p>

                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.name}
                        name="name"
                        label={getT(translationKeys.name)}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.surname}
                        name="surname"
                        label={getT(translationKeys.surname)}
                      />
                    </Col>
                    {/* <Col md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.socialSecurity}
                        name="socialSecurity"
                        label={getT(translationKeys.socialSecurity)}
                      />
                    </Col> */}
                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.city}
                        name="city"
                        label={getT(translationKeys.city)}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.state}
                        name="state"
                        label={getT(translationKeys.state)}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.country}
                        name="country"
                        label={getT(translationKeys.country)}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.zip}
                        name="zip"
                        label={getT(translationKeys.zip)}
                      />
                    </Col>
                    <Col md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.phone}
                        name="phone"
                        label={getT(translationKeys.phone)}
                      />
                    </Col>
                    {/*<Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.email}
                            name="email"
                            label={getT(translationKeys.email)}
                          />
                        </Col>
                        <Row>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.password}
                              name="password"
                              label={getT(translationKeys.password)}
                            />
                          </Col>
                          <Col md={6}>
                            <CustomInput
                              onEdit={false}
                              defaultValue={values.confirmPassword}
                              name="confirmPassword"
                              label={getT(translationKeys.confirmPassword)}
                            />
                          </Col>
              </Row> */}

                    {/* <Col>
                      <p>{getT("cart_company_extra_title")}</p>
                    </Col>

                    <Col md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.company}
                        name="company"
                        label={getT(translationKeys.company)}
                      />
                    </Col>
                    <Col md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.iva}
                        name="iva"
                        label={getT(translationKeys.iva)}
                      />
                    </Col>*/}

                    <Col md={12} className="mb-2 mt-2">
                      <div>
                        <Field
                          type="checkbox"
                          className="form-check-input me-2"
                          name="is_shipping_same"
                          onClick={() => {
                            /*if (values.is_shipping_same) {
                                  setSchema(initialSchema);
                                } else {
                                  setSchema(updateSchema);
                                }*/
                          }}
                        />
                        <label>
                          {getT(translationKeys.is_shipping_same_label)}{" "}
                        </label>
                      </div>
                    </Col>
                    {!values.is_shipping_same && (
                      <>
                        <h2>{getT("cart_shipping_address_title")}</h2>
                        <p>{getT("cart_shipping_address_sub_title")}</p>

                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingName}
                            name="shippingName"
                            label={getT(translationKeys.name)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingSurname}
                            name="shippingSurname"
                            label={getT(translationKeys.surname)}
                          />
                        </Col>
                        {/* <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCodiceFiscale}
                            name="shippingCodiceFiscale"
                            label={getT(translationKeys.socialSecurity)}
                          />
                        </Col> */}

                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCity}
                            name="shippingCity"
                            label={getT(translationKeys.city)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingState}
                            name="shippingState"
                            label={getT(translationKeys.state)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCountry}
                            name="shippingCountry"
                            label={getT(translationKeys.country)}
                          />
                        </Col>
                        <Col md={6}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingZip}
                            name="shippingZip"
                            label={getT(translationKeys.zip)}
                          />
                        </Col>
                        {/* <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingCompany}
                            name="shippingCompany"
                            label={getT(translationKeys.company)}
                          />
                        </Col> */}

                        <Col md={12}>
                          <CustomInput
                            onEdit={false}
                            defaultValue={values.shippingPhone}
                            name="shippingPhone"
                            label={getT(translationKeys.phone)}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <Col className="mt-2">
                    {/* <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT("submit")}
                    </button> */}
                    {/* <button
                        type="button"
                        onClick={() => onBack()}
                        className="btn btn-secondary"
                      >
                        back
                  </button> */}
                  </Col>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Shipping;

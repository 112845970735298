import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import {
  useCreateEventMutation,
  useEventQuery,
  useUpdateEventMutation,
} from "../../queries/events";
import { useUserQuery } from "../../queries/users";
import { useQuoteQuery } from "../../queries/quotes";
import {
  CUSTOMERROLE,
  OPERATORROLE,
  eventStatusList,
  formatMysqlDate,
  getT,
  translationKeys,
} from "../utils";
import { useAuth } from "../../authContext";
import Select from "react-select";
import CustomSelect from "./utils/CustomSelect";
import CustomInput from "./utils/CustomInput";
import CustomTextArea from "./utils/CustomTextArea";
import moment from "moment";

const EventForm = ({
  onEdit = false,
  editId = null,
  onClose = () => {},
  givenDate = null,
}) => {
  const [submittable, setSubmittable] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [quotesData, setQuotesData] = useState([]);
  const [editData, setEditData] = useState();
  const { authUserId, userRoleName } = useAuth();
  const formattedDefaultDate = moment(givenDate).format("YYYY-MM-DDTHH:mm");

  const navigate = useNavigate();
  const { data, isIdle, isLoading, isError, error } = useEventQuery(
    editId,
    editId ? true : false
  );

  const {
    data: userData,
    isIdle: userIdle,
    isLoading: userLoading,
    isError: userError,
  } = useUserQuery();

  const {
    data: qData,
    isIdle: qIdle,
    isLoading: qLoading,
    isError: qError,
  } = useQuoteQuery();

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateEventMutation)
    : (mutationHook = useCreateEventMutation);
  const mutation = mutationHook();

  const newSchema = {
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    description: Yup.string().required(
      getT(translationKeys.descriptionRequired)
    ),
    start: Yup.string().required(getT(translationKeys.startDateRequired)),
    end: Yup.string().required(getT(translationKeys.endDateRequired)),
    // from: Yup.number().required("from person is required"),
    to: Yup.object().required(getT(translationKeys.toRequired)),
    quote_id: Yup.object().required(getT(translationKeys.quoteRequired)),
    //status: Yup.string().required("status is required"),
  };

  const editSchema = {
    description: Yup.string().required(getT(translationKeys.name)),
  };

  const formSchema = Yup.object().shape(onEdit ? editSchema : newSchema);

  useEffect(() => {
    if (data && !isLoading) {
      setEditData(data?.event);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (userData && !userLoading && userData.users) {
      setUsersData(userData?.users);
    }
  }, [userLoading, userData]);

  useEffect(() => {
    if (qData && !qLoading && qData.quotes) {
      setQuotesData(qData?.quotes);
    }
  }, [qLoading, qData]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.event &&
        mutation.data.data.event.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        onClose();
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const finalData = {
      name: values.name,
      description: values.description,
      start_date: values.start,
      end_date: values.end,
      from_user_id: values.from,
      to_user_id: values.to?.value,
      quote_id: values.quote_id?.value,
      status: values.status?.value,
      installers: values.installers,
      notes: values.notes,
      user_id: authUserId,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    //navigate(paths.rolesPath);
  };

  if (isLoading || userLoading || userIdle || qLoading || qIdle) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  const getUsersByType = (type) => {
    let users = [];
    usersData.forEach((u) => {
      if (u.role && u.role.name === type) {
        users.push(u);
      }
    });
    return users && users.length > 0
      ? users.map((u) => {
          return {
            value: u.id,
            label: u.name,
          };
        })
      : [];
  };

  const getQuotesByUser = (vals) => {
    let user = {};
    let quotes = [];
    usersData.forEach((u) => {
      if (u.id === parseInt(vals.to.value)) {
        user = u;
      }
    });

    if (user && user.email) {
      quotesData.forEach((q) => {
        if (q.email === user.email) {
          quotes.push({
            value: q.id,
            label: q.id + "-" + formatMysqlDate(q?.created_at),
          });
        }
      });
    }
    return quotes;
  };

  const getInitialUserInfo = (id) => {
    let user = {};
    usersData.forEach((u) => {
      if (u.id === parseInt(id)) {
        user = {
          value: u.id,
          label: u.name,
        };
      }
    });
    return user;
  };

  const getInitialQuoteInfo = (id) => {
    let quote = {};

    quotesData.forEach((q) => {
      if (q.id === id) {
        quote = {
          value: q.id,
          label: q.id,
        };
      }
    });

    return quote;
  };

  const getInitialStatus = (id) => {
    let status = {};

    eventStatusList.forEach((q) => {
      if (q.value === id) {
        status = {
          value: q.value,
          label: q.name,
        };
      }
    });

    return status;
  };

  return (
    <Container fluid className="EventForm">
      <div className="leadFormInner">
        {(editData && onEdit) || !onEdit ? (
          <Formik
            initialValues={{
              name: editData?.name ?? "",
              description: editData?.description ?? "",
              status: getInitialStatus(editData?.status),
              from:
                editData?.from_user_id && editData.from_user_id
                  ? editData.from_user_id
                  : authUserId,
              to: getInitialUserInfo(editData?.to_user_id),
              start: editData?.start_date
                ? editData.start_date
                : formattedDefaultDate,
              end: editData?.end_date,
              quote_id: getInitialQuoteInfo(editData?.quote_id),
              installers: editData?.installers,
              notes: editData?.notes,
            }}
            validationSchema={formSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomInput
                      onEdit={false}
                      defaultValue={values.name}
                      name="name"
                      label={getT(translationKeys.name)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.client)}
                      name="to"
                      defaultValue={values.to}
                      options={getUsersByType("customer")}
                    />
                  </Col>
                  {/* <Col sm={12} md={6}>
                    <CustomSelect
                      label={getT(translationKeys.quote)}
                      name="quote_id"
                      defaultValue={values.quote_id}
                      options={getQuotesByUser(values)}
                    />
                  </Col> */}
                </Row>
                <Row>
                  {/* <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.installers)}
                      name="installers"
                      isMulti={true}
                      defaultValue={values.installers}
                      options={getUsersByType("installer")}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="start"
                      label={getT(translationKeys.startDate)}
                      type="datetime-local"
                      defaultValue={values.start}
                      placeholder=""
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <CustomInput
                      name="end"
                      label={getT(translationKeys.endDate)}
                      type="datetime-local"
                      defaultValue={values.end}
                      placeholder=""
                    />
                  </Col>
                </Row>

                {userRoleName !== CUSTOMERROLE && (
                  <Row>
                    <Col sm={12} md={12}>
                      <CustomTextArea
                        name="description"
                        label={getT(translationKeys.description)}
                        defaultValue={values.description}
                        placeholder=""
                      />
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col sm={12} md={12}>
                    <CustomTextArea
                      name="notes"
                      label={getT(translationKeys.notes)}
                      defaultValue={values.notes}
                      placeholder=""
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12} md={12}>
                    <CustomSelect
                      label={getT(translationKeys.status)}
                      name="status"
                      defaultValue={values.status}
                      options={
                        eventStatusList &&
                        eventStatusList?.map((option, index) => ({
                          label: option.name,
                          value: option.value,
                        }))
                      }
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col>
                    <button
                      type="submit"
                      disabled={submittable}
                      className="btn btn-success me-2"
                    >
                      {getT(translationKeys.submit)}
                    </button>
                    {/* <button
                      type="button"
                      onClick={() => handleClear(formik)}
                      className="btn btn-secondary"
                    >
                      cancel
                        </button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        ) : (
          <Loading />
        )}
      </div>
    </Container>
  );
};

export default EventForm;

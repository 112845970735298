import React, { useState } from "react";
import WrapperModal from "../../components/ModalWrapper";
import { eventStatusList, getT, translationKeys } from "../../admin/utils";
import AppointmentUpdateForm from "../../admin/forms/AppointmentUpdateForm";
import ViewOrder from "../../admin/Orders/ViewOrder";
import InfoOrder from "../../admin/Orders/InfoOrder";

export default function OrderInfoWidgetModal({
  cusId = null,
  showModal,
  setShowModal = () => {},
  orderId = null,
}) {
  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT("order_info_modal_title")}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <InfoOrder roleType={"customer"} cusId={cusId} orderId={orderId} />
      </WrapperModal>
    </>
  );
}

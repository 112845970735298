import React, { useEffect, useState } from "react";
import Cart from "./cart";
import { updateOrderProductsData } from "../../utils/helpers";
import { toast } from "react-toastify";
import axios from "axios";
import { apiUrl } from "../../config/paths";
import { useAuth } from "../../authContext";

export default function CartWrapper() {
  const { order, updateOrderInfo } = useAuth({});
  const [initialId, setInitialId] = useState(
    localStorage.getItem("orderId") ? localStorage.getItem("orderId") : null
  );

  // getting previous data
  useEffect(() => {
    if (initialId) {
      readRequestData();
    }
  }, [initialId]);

  useEffect(() => {
    if (order && order.products) {
      onOrderChange();
    }
  }, [order]);

  // Function to create a row and get the ID
  const readRequestData = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/cusOrder/" + initialId);
      if (
        response.status === 200 &&
        response.data &&
        response.data.order &&
        response.data.order.id > 0
      ) {
        // do the initial setup
        let initialData = response.data.order;
        updateOrderInfo(initialData);
      } else {
        console.log(response.status, response.statusText);
      }
    } catch (error) {
      console.error("Error reading row:", error);
      //toast.error("try again later");
    }
  };

  const onOrderChange = async () => {
    let ids = [];

    if (order?.products && order.products.length > 0) {
      order.products.forEach((p) => {
        ids.push({ id: p.product_id, q: p.quantity });
      });
    }

    let res = await updateOrderProductsData({
      product_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }
  };

  const itemExists = (product) => {
    return order?.products && order.products.length > 0
      ? order.products.find((item) => item?.product_id === product.id)
      : false;
  };

  const onQuanityChange = (q, product) => {
    let prodInputEle = document.getElementById("product_" + product.id);
    if (q >= 1) {
      if (itemExists(product)) {
        updateOrderInfo((prevCart) => ({
          ...prevCart,
          products: prevCart.products.map((p) => {
            if (p.product_id === product.id) {
              p.quantity = q;
            }
            return p;
          }),
        }));
      }
    } else {
      updateOrderInfo((prevCart) => ({
        ...prevCart,
        products: prevCart.products.filter((p) => p.product_id !== product.id),
      }));
      prodInputEle.value = 1;
      //alert("quantity must be greater than 1");
    }
  };

  return (
    <>
      <Cart order={order} onUpdateQuantity={onQuanityChange} />
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import paths from "../../config/paths";
import Loading from "../../components/Loading";
import { useAuth } from "../../authContext";
import { getT, translationKeys } from "../utils";
import CustomInput from "./utils/CustomInput";
import {
  useCreateProductMutation,
  useProductQuery,
  useUpdateProductMutation,
} from "../../queries/products";
import CustomTextArea from "./utils/CustomTextArea";
import CustomSelect from "./utils/CustomSelect";
import { useCategoryQuery } from "../../queries/categories";
import { BsImage } from "react-icons/bs";
import WrapperModal from "../../components/ModalWrapper";
import MediaPicker from "../MediaPicker";
import CustomSelect2 from "./utils/CustomSelect2";

const ProductForm = ({ onEdit = false, editId = null }) => {
  const [submittable, setSubmittable] = useState(false);
  const [editData, setEditData] = useState();
  const [cats, setCats] = useState([]);
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { data, isIdle, isLoading, isError, error } = useProductQuery(
    editId,
    editId ? true : false
  );

  const productTypeOptions = [
    {
      name: "single",
      value: "single",
    },
    {
      name: "multiple",
      value: "multiple",
    },
  ];

  const {
    data: catData,
    isIdle: catIdle,
    isLoading: catLoading,
    isError: catError,
  } = useCategoryQuery();

  const { TData } = useAuth();

  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);

  let mutationHook;

  onEdit
    ? (mutationHook = useUpdateProductMutation)
    : (mutationHook = useCreateProductMutation);
  const mutation = mutationHook();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(getT(translationKeys.nameRequired)),
    price: Yup.string().required(getT(translationKeys.priceRequired)),
    fullprice: Yup.string().required(getT(translationKeys.fullpriceRequired)),
  });

  useEffect(() => {
    if (catData && !catLoading && catData.items) {
      let items = catData.items.map((c) => {
        return { label: c.name, value: c.id };
      });
      setCats(items);
    }
  }, [catLoading, catData]);

  useEffect(() => {
    if (data && !isLoading && data?.item) {
      setEditData(data?.item);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (mutation.isSuccess) {
      toast.dismiss();
      if (
        mutation.data.data &&
        mutation.data.data.item &&
        mutation.data.data.item.id > 0
      ) {
        onEdit
          ? toast.success(getT(translationKeys.updated))
          : toast.success(getT(translationKeys.created));
        //navigate(paths.productsPath);
        navigate("/adminside/products/edit/" + mutation.data.data.item.id);
      }
      mutation.reset();
    }
    if (mutation.isError) {
      toast.dismiss();
      if (
        mutation.error &&
        mutation.error.response &&
        mutation.error.response.data &&
        mutation.error.response.data.message
      ) {
        toast.error(mutation.error.response.data.message);
      } else {
        toast.error(getT(translationKeys.generalError));
      }
      mutation.reset();
    }
  }, [mutation]);

  const handleSubmit = async (values, { setSubmitting }) => {
    //console.log(values);
    const finalData = {
      subtitle: values.subtitle,
      name: values.name,
      code: values.code,
      sales: values.sales,
      startoffer: values.startoffer,
      endoffer: values.endoffer,
      shipping: values.shipping,
      price: values.price,
      fullprice: values.fullprice,
      expired: values.expired,
      categoryIds: values.categoryIds.map((c) => c.value),
      description: values.description,
      permalink: values.permalink,
      product_type: values.product_type,
      gallery_image_one: values.gallery_image_one,
      gallery_image_two: values.gallery_image_two,
      gallery_image_three: values.gallery_image_three,
    };

    toast.warning(getT(translationKeys.pleaseWait));

    if (onEdit) {
      mutation.mutate({ id: editData.id, data: finalData });
    } else {
      mutation.mutate(finalData);
    }
  };

  const handleClear = (formik) => {
    //formik.resetForm();
    navigate(paths.productsPath);
  };

  const getInitialCategoryInfo = (data) => {
    let items = [];
    if (data && data.length > 0) {
      data.forEach((id) => {
        cats.forEach((c) => {
          if (parseInt(id) === parseInt(c.value)) {
            items.push(c);
          }
        });
      });
    }

    return items;
  };

  const onImageUpate = (val) => {
    if (val !== "") {
      console.log(val, "");
      if (formikRef && formikRef.current) {
        formikRef.current.setFieldValue(selectedType, val);
      }
    }
    setShowModal(!showModal);
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div className="leadForm">{getT(translationKeys.generalError)}</div>;
  }

  return (
    <>
      <WrapperModal
        show={showModal}
        title={getT(translationKeys.media)}
        handleClose={() => {
          setShowModal(!showModal);
        }}
      >
        <MediaPicker onSelected={onImageUpate} sFile={selectedFile} />
      </WrapperModal>
      <Container fluid className="leadForm">
        <button
          onClick={() => {
            navigate(paths.productsPath);
          }}
          className="btn btn-secondary "
        >
          {getT(translationKeys.back)}
        </button>
        <h4 className="my-2">
          {onEdit ? getT(translationKeys.update) : getT(translationKeys.create)}
        </h4>
        <div className="leadFormInner">
          {(editData && cats && cats.length > 0 && onEdit) ||
          (!onEdit && cats) ? (
            <Formik
              initialValues={{
                name: editData?.name ?? "",
                title: editData?.title ?? "",
                subtitle: editData?.subtitle ?? "",
                price: editData?.price ?? "",
                fullprice: editData?.fullprice ?? "",
                sales: editData?.sales && editData?.sales ? true : false,
                code: editData?.code ?? "",
                startoffer: editData?.startoffer ?? "",
                endoffer: editData?.endoffer ?? "",
                shipping: editData?.shipping ?? "",
                description: editData?.description ?? "",
                expired: editData?.expired && editData?.expired ? true : false,
                categoryIds: getInitialCategoryInfo(editData?.categoryIds),
                permalink: editData?.permalink ?? "",
                gallery_image_one: editData?.gallery_image_one ?? "",
                gallery_image_two: editData?.gallery_image_two ?? "",
                gallery_image_three: editData?.gallery_image_three ?? "",
                product_type: editData?.product_type ?? "single",
              }}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
              innerRef={formikRef}
            >
              {({ values, setFieldValue, formik }) => (
                <Form>
                  <Row>
                    <Col md={1}>
                      <div className="d-flex">
                        <Field
                          type="checkbox"
                          id="sales"
                          className="form-check-input me-2"
                          name="sales"
                        />
                        <label className="me-2">
                          {getT(translationKeys.sales)}{" "}
                        </label>
                      </div>
                    </Col>
                    <Col md={11}>
                      <div>
                        <Field
                          type="checkbox"
                          id="expired"
                          className="form-check-input me-2"
                          name="expired"
                        />
                        <label className="me-2">
                          {getT(translationKeys.expired)}{" "}
                        </label>
                      </div>
                    </Col>
                    {values.expired && (
                      <>
                        <Col sm={12} md={6}>
                          <CustomInput
                            onEdit={false}
                            type="datetime-local"
                            defaultValue={values.startoffer}
                            name="startoffer"
                            label={getT(translationKeys.startOffer)}
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <CustomInput
                            onEdit={false}
                            type="datetime-local"
                            defaultValue={values.endoffer}
                            name="endoffer"
                            label={getT(translationKeys.endOffer)}
                          />
                        </Col>{" "}
                      </>
                    )}
                    <Col sm="12" md="6">
                      <CustomSelect
                        label={getT(translationKeys.categories)}
                        name="categoryIds"
                        isMulti={true}
                        defaultValue={values.categoryIds}
                        options={cats}
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <CustomSelect2
                        label={getT("type")}
                        name="product_type"
                        options={productTypeOptions}
                      />
                    </Col>
                    <Col sm={12} md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.name}
                        name="name"
                        label={getT(translationKeys.name)}
                      />
                    </Col>
                    <Col sm={12} md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.permalink}
                        name="permalink"
                        label={getT(translationKeys.permalink)}
                      />
                    </Col>
                    <Col sm={12} md={12}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.subtitle}
                        name="subtitle"
                        label={getT(translationKeys.subtitle)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={12} md={4}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.price}
                        name="price"
                        type="number"
                        label={getT(translationKeys.price)}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.fullprice}
                        name="fullprice"
                        type="number"
                        label={getT(translationKeys.fullPrice)}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.shipping}
                        name="shipping"
                        type="number"
                        label={getT(translationKeys.shipping)}
                      />
                    </Col>
                    <Col sm={12} md={4}>
                      <CustomInput
                        onEdit={false}
                        defaultValue={values.code}
                        name="code"
                        label={getT(translationKeys.code)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={12}>
                      <CustomTextArea
                        name="description"
                        label={getT(translationKeys.description)}
                        defaultValue={values.description}
                        placeholder=""
                      />
                    </Col>
                  </Row>
                  <Col md={12}>
                    <label>
                      <span>image source1</span>
                      <span>
                        <BsImage
                          onClick={() => {
                            setShowModal(!showModal);
                            setSelectedType("gallery_image_one");
                          }}
                          fontSize={25}
                          className="p-1 cursor"
                        />
                      </span>
                    </label>
                    <CustomInput
                      label=""
                      defaultValue={values?.gallery_image_one}
                      name="gallery_image_one"
                    />
                  </Col>

                  <Col md={12}>
                    <label>
                      <span>image source2</span>
                      <span>
                        <BsImage
                          onClick={() => {
                            setShowModal(!showModal);
                            setSelectedType("gallery_image_two");
                          }}
                          fontSize={25}
                          className="p-1 cursor"
                        />
                      </span>
                    </label>
                    <CustomInput
                      label=""
                      defaultValue={values?.gallery_image_two}
                      name="gallery_image_two"
                    />
                  </Col>

                  <Col md={12}>
                    <label>
                      <span>image source3</span>
                      <span>
                        <BsImage
                          onClick={() => {
                            setShowModal(!showModal);
                            setSelectedType("gallery_image_three");
                          }}
                          fontSize={25}
                          className="p-1 cursor"
                        />
                      </span>
                    </label>
                    <CustomInput
                      label=""
                      defaultValue={values?.gallery_image_three}
                      name="gallery_image_three"
                    />
                  </Col>
                  {/* <Row className="mt-2">
                  <Col sm={12} md={12}>
                    <label>File</label>
                    <input
                      type="file"
                      name="file"
                      ref={fileInputRef}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                      }}
                      className="form-control my-2"
                    />
                  </Col>
                    </Row> */}
                  <Row className="mt-2">
                    {/* <Col sm={12} md={4}>
                    <label className="form-label" htmlFor="firstName">
                      {getT(translationKeys.source)}{" "}
                    </label>

                    <Field
                      as="select"
                      className="form-select"
                      placeholder={getT(translationKeys.source)}
                      id="referal_type"
                      name="referal_type"
                    >
                      {fonteOptions.map((option, index) => (
                        <option
                          key={index}
                          value={option.value}
                          label={option.label}
                        />
                      ))}
                      <ErrorMessage
                        className="danger"
                        name="referal_type"
                        component="div"
                      />
                    </Field>
                      </Col> */}
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <button
                        type="submit"
                        disabled={submittable}
                        className="btn btn-success me-2"
                      >
                        {getT(translationKeys.submit)}
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClear(formik)}
                        className="btn btn-secondary"
                      >
                        {getT(translationKeys.back)}
                      </button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          ) : (
            <Loading />
          )}
        </div>
      </Container>
    </>
  );
};

export default ProductForm;

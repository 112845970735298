import React, { useState } from "react";
import { apiUrl } from "../../config/paths";
import { updateOrderData } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { getFolderPath } from "../../utils/api";
import { currencyType, getT } from "../../admin/utils";
import { BiSolidMinusCircle } from "react-icons/bi";
import {
  BsBinoculars,
  BsPlusCircleDotted,
  BsSearch,
  BsZoomIn,
} from "react-icons/bs";
import Lightbox from "yet-another-react-lightbox";
import { MdOutlineZoomIn } from "react-icons/md";
import { FaShareNodes } from "react-icons/fa6";

export default function Product({
  info = {},
  onAdd = () => {},
  itemExists = () => {},
  onQuanityChange = () => {},
  order = {},
  selectedCats = [],
  key = 0,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const getImageInfo = () => {
    let final = "";
    if (info?.gallery_image_one && info?.gallery_image_one != "") {
      final = info?.gallery_image_one;
    }
    return final;
  };

  const getDefaultVariation = (id) => {
    let val = false;
    if (order && order.variations && order.variations.length > 0) {
      order.variations.forEach((v) => {
        if (v.product_option_id === id) {
          val = true;
        }
      });
    }
    return val;
  };

  const onVariationChange = async (id, productId) => {
    /*let ids = [];

    if (order && order.variations && order.variations.length > 0) {
      order.variations.forEach((v) => {
        
      });
    }

    let res = await updateOrderData({
      product_id: productId,
      variation_ids: ids,
    });

    if (res.status) {
      // do something
    } else {
      console.log("update failed");
    }*/
  };

  const checkValuesExist = (arr1, arr2) => {
    for (let i = 0; i < arr2.length; i++) {
      if (arr1.includes(arr2[i])) {
        return true;
      }
    }
    return false;
  };

  const getProdStatus = () => {
    if (selectedCats && selectedCats.length > 0) {
      if (info.categoryIds && info.categoryIds.length != null) {
        return checkValuesExist(info.categoryIds, selectedCats);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const getImageList = () => {
    let finalItems = [];
    if (info?.gallery_image_one) {
      finalItems.push({ src: info?.gallery_image_one });
    }
    if (info?.gallery_image_two) {
      finalItems.push({ src: info?.gallery_image_two });
    }
    if (info?.gallery_image_three) {
      finalItems.push({ src: info?.gallery_image_three });
    }
    //console.log(finalItems);
    return finalItems;
  };

  const getPercent = () => {
    let percent = 0;
    if (info?.price > 0 && info?.fullprice > 0) {
      percent = (info?.price / info?.fullprice) * 100;
    }
    percent = Math.round(percent);
    return percent + "%";
  };

  return (
    <>
      <div
        className={"e_prod_list_item " + (getProdStatus() ? "show" : "hide")}
        key={key}
      >
        <div className="imgWrapper">
          <img
            onClick={() => {
              //navigate("/shop/" + info?.permalink);
            }}
            src={getImageInfo()}
            alt=""
          />
        </div>

        {/* <div className="category_list">
        {info?.categories &&
          info?.categories.map((c) => {
            return (
              <div className="category_list_item">
                <div>{c?.name}</div>
                <select
                  onChange={(e) => {
                    //setDynamicVal(e.target.value, c.name);
                    onVariationChange(e.target.value, info.id);
                  }}
                >
                  <option value={-1}>choose</option>
                  {c?.variations &&
                    c.variations.map((v) => {
                      return (
                        <option
                          selected={getDefaultVariation(v.id)}
                          value={v.id}
                        >
                          {v.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            );
          })}
        </div> */}
        <div className="subInfo">
          {/* <input
          type="number"
          style={{
            width: "auto",
            border: "1px solid #000",
            color: "#000",
            opacity: 0,
          }}
          defaultValue={1}
          onChange={(event) => {
            onQuanityChange(event.target.value, info);
          }}
          id={"product_" + info.id}
          className="form-control quantity"
        /> */}
          <div
            onClick={() => {
              //navigate("/shop/" + info?.permalink);
            }}
            className="title"
          >
            {info?.name}
          </div>
          <div className="priceInfo">
            <span className="priceInfoInner">
              <span className="fullPrice">{currencyType + info?.price}</span>
              <span className="price">{currencyType + info?.fullprice}</span>
            </span>
            <div
              className={
                itemExists(info)
                  ? " cursor delete_to_cart_btn "
                  : " cursor add_to_cart_btn"
              }
              onClick={() => {
                onAdd(info);
              }}
            >
              {itemExists(info) ? getT("REMOVE") : getT("ADD")}
            </div>
          </div>
        </div>
        <div className="actionBtns d-flex">
          {/* <div className="share_btn" onClick={()=>{}}>
            <FaShareNodes />
          </div> */}
          <div className={"zoom_btn"} onClick={() => setOpen(true)}>
            <BsZoomIn className="cursor" />
          </div>
        </div>
        <div className="discountbar">
          <span>{getPercent(info) + " OFF"}</span>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={getImageList()}
      />
    </>
  );
}
